/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AboutusCareerListData = CareerPageModel;

export type AboutusCareerListError = string[];

export type AboutusContactListData = ContactPageModel;

export type AboutusContactListError = string[];

export type AboutusInmediaListData = ContactPageModel;

export type AboutusInmediaListError = string[];

export type AboutusInsertcommentCreateData = boolean;

export type AboutusInsertcommentCreateError = string[];

export type AboutusOurteamListData = OurTeamPageModel;

export type AboutusOurteamListError = string[];

export type AboutusWhoweareListData = WhoWeArePageModel;

export type AboutusWhoweareListError = string[];

export interface AggregateRouteConfig {
  jsonPath?: string | null;
  parameter?: string | null;
  routeKey?: string | null;
}

export interface AgraIntegrationCompanyInfo {
  /** @format int32 */
  financialPeriod?: number;
  sector?: string | null;
  title?: string | null;
  vknTckn?: string | null;
}

export interface AgraIntegrationUserInfo {
  name?: string | null;
  phoneNumber?: string | null;
  roles?: string[] | null;
  /** @format uuid */
  userTrackingId?: string;
}

export type AuthServerAddCompanyCreateData = ResponseCompanyInfoBaseResponseJsonModel;

export type AuthServerAddCompanyCreateError = BaseResponseJsonModel1;

export type AuthServerChangeEmailCreateData = BooleanBaseResponseJsonModel;

export type AuthServerChangeEmailCreateError = BaseResponseJsonModel1;

export type AuthServerChangePasswordCreateData = BooleanBaseResponseJsonModel;

export type AuthServerChangePasswordCreateError = BaseResponseJsonModel1;

export type AuthServerCompleteActivationPartialUpdateData = BooleanBaseResponseJsonModel;

export type AuthServerCompleteActivationPartialUpdateError = BaseResponseJsonModel1;

export type AuthServerDeleteCompanyDeleteData = BooleanBaseResponseJsonModel;

export type AuthServerDeleteCompanyDeleteError = BaseResponseJsonModel1;

export interface AuthServerDeleteCompanyDeleteParams {
  /** @format int32 */
  id?: number;
}

export type AuthServerGenerateAgraTokenListData = GuidBaseResponseJsonModel;

export type AuthServerGenerateAgraTokenListError = BaseResponseJsonModel1;

export type AuthServerInfoListData = ResponseGetMeBaseResponseJsonModel;

export type AuthServerInfoListError = BaseResponseJsonModel1;

export type AuthServerInfoWithCompaniesListData = ResponseGetMeWithCompanyDetailsBaseResponseJsonModel;

export type AuthServerInfoWithCompaniesListError = BaseResponseJsonModel1;

export type AuthServerIsCheckPhoneAndEmailDetailData = ResponseIsCheckPhoneEmailBaseResponseJsonModel;

export type AuthServerIsCheckPhoneAndEmailDetailError = BaseResponseJsonModel1;

export type AuthServerLoginCreateData = ResponseAuthSigninBaseResponseJsonModel;

export type AuthServerLoginCreateError = BaseResponseJsonModel1;

export type AuthServerLoginListData = ProfilePageModel;

export type AuthServerLoginListError = ProfilePageModel;

export type AuthServerLogoutListData = BooleanBaseResponseJsonModel;

export type AuthServerLogoutListError = BaseResponseJsonModel1;

export type AuthServerOtpSendCreateData = BooleanBaseResponseJsonModel;

export type AuthServerOtpSendCreateError = BaseResponseJsonModel1;

export type AuthServerProfileListData = ResponseGetMeWithCompanyDetailsBaseResponseJsonModel;

export type AuthServerProfileListError = BaseResponseJsonModel1;

export type AuthServerRefreshTokenCreateData = ResponseRefreshTokenBaseResponseJsonModel;

export type AuthServerRefreshTokenCreateError = BaseResponseJsonModel1;

export type AuthServerRemoveAccountDeleteData = BooleanBaseResponseJsonModel;

export type AuthServerRemoveAccountDeleteError = BaseResponseJsonModel1;

export type AuthServerResetPasswordCreateData = ResponsePasswordResetBaseResponseJsonModel;

export type AuthServerResetPasswordCreateError = BaseResponseJsonModel1;

export type AuthServerResetPasswordListData = ProfilePageModel;

export type AuthServerResetPasswordListError = ProfilePageModel;

export type AuthServerResetPasswordTokenCreateData = ResponseResetPasswordTokenBaseResponseJsonModel;

export type AuthServerResetPasswordTokenCreateError = BaseResponseJsonModel1;

export type AuthServerResetPasswordTokenListData = ProfilePageModel;

export type AuthServerResetPasswordTokenListError = ProfilePageModel;

export type AuthServerSignUpCreateData = ResponseAuthSignupBaseResponseJsonModel;

export type AuthServerSignUpCreateError = BaseResponseJsonModel1;

export type AuthServerSignUpListData = ProfilePageModel;

export type AuthServerSignUpListError = ProfilePageModel;

export type AuthServerSuccessPasswordListData = ProfilePageModel;

export type AuthServerSuccessPasswordListError = ProfilePageModel;

export type AuthServerVerifyAgraTokenDetailData = ResponseIntegrationAgraUserInfoBaseResponseJsonModel;

export type AuthServerVerifyAgraTokenDetailError = BaseResponseJsonModel1;

export interface AuthorDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  articles?: ResponseStoreFrontAuthorArticleModelListPagedApiResponse;
  author?: ResponseStoreFrontAuthorDetailModel;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categories?: ResponseStoreFrontCategoryList[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface AverageSalaryPerEmployee {
  text?: string | null;
  /** @format int32 */
  value?: number;
}

export enum BackgroundType {
  None = 'None',
  Color = 'Color',
}

export enum BadgeType {
  Yeni = 'Yeni',
  SizeOzel = 'SizeOzel',
  Yakinda = 'Yakinda',
}

export enum BankDigitalOnBoardingInfoCampaignType {
  Information = 'Information',
  Campaign = 'Campaign',
}

export interface BankingModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  posProducts?: PosProductBankProductsListModel[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface BaseResponseJsonModel1 {
  data?: T;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export type BlogArticleDetailError = string[] | string;

export type BlogAuthorDetailData = AuthorDetailModel;

export type BlogAuthorDetailError = string[] | string;

export interface BlogAuthorDetailParams {
  /** @format int32 */
  Page?: number;
  friendlyUrl: string;
}

export type BlogCategoryDetailData = CategoryDetailModel;

export type BlogCategoryDetailError = string[] | string;

export interface BlogCategoryDetailParams {
  /** @format int32 */
  Limit?: number;
  /** @format int32 */
  Page?: number;
  Order?: string;
  friendlyUrl: string;
}

export type BlogCommentsArticleDetailError = string[] | string;

export interface BlogCommentsArticleDetailParams {
  /** @format int32 */
  ParentCommentId?: number;
  /** @format int32 */
  Page?: number;
  /** @format int32 */
  Limit?: number;
  /** @format int32 */
  articleId: number;
}

export type BlogDetailData = ResponseModuleStoreFront;

export type BlogDetailError = string[];

export type BlogHomeListData = ResponseStoreFrontHomePageModel;

export type BlogHomeListError = string[];

export type BlogSearchListData = ResponseStoreFrontSearchPageModel;

export type BlogSearchListError = string[];

export interface BlogSearchListParams {
  Query?: string;
}

export type BlogStoreCommentsCreateData = ResponseStoreFrontArticleVote;

export type BlogStoreCommentsCreateError = string[];

export type BlogStoreCommentsLikeCreateData = ResponseStoreFrontArticleVote;

export type BlogStoreCommentsLikeCreateError = string[];

export type BlogStoreSurvivorArticlesLastListData = ResponseLastNews;

export type BlogStoreSurvivorArticlesLastListError = string[] | string;

export interface BlogStoreSurvivorArticlesLastListParams {
  /** @format int32 */
  categoryId?: number;
  /** @format int32 */
  count?: number;
}

export type BlogStoreVoteCreateData = ResponseStoreFrontArticleVote;

export type BlogStoreVoteCreateError = string[];

export interface BooleanBaseResponseJsonModel {
  data?: boolean;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface BreadCrumbModel {
  item?: string | null;
  url?: string | null;
}

export interface CalculateByInterestRateModel {
  /** @format double */
  balance?: number;
  /** @format double */
  bsmv?: number;
  /** @format int32 */
  currentMaturity?: number;
  /** @format double */
  interest?: number;
  /** @format double */
  kkdf?: number;
  /** @format double */
  mainAmount?: number;
  /** @format double */
  monthlyInstallment?: number;
}

export type CalculationListData = ResponseProductModuleCalculate;

export type CalculationListError = string[];

export interface CalculationListParams {
  /** @format int32 */
  TypeId?: number;
  /** @format int32 */
  ProductId?: number;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  BankSeoUrl?: string;
  /** @format int32 */
  BankFinanceType?: number;
  NewFeature?: string;
  /** @format int32 */
  DeviceType?: number;
}

export interface CalculationToolsPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  widgets?: ResponseWidget[] | null;
}

export type CalculationsGrosstonetCreateData = ResponseStoreGrossNetSalary;

export type CalculationsGrosstonetCreateError = string[];

export type CalculationsGrosstonetListData = GrossToNetCalculationPageModel;

export type CalculationsGrosstonetListError = string[];

export type CalculationsSendmailCreateData = ResponseStoreGrossNetSalary;

export type CalculationsSendmailCreateError = string[];

export type CalculationsSeverancepayCreateData = ResponseStoreFrontSeveranceAndNoticePay;

export type CalculationsSeverancepayCreateError = string[];

export type CalculationsSeverancepayListData = SeveranceAndNoticePayPageModel;

export type CalculationsSeverancepayListError = string[];

export type CalculationsUnemploymentPayCreateData = ResponseStoreUnemploymentPay;

export type CalculationsUnemploymentPayCreateError = string[];

export type CalculationsUnemploymentPayListData = UnemploymentPayCalculationPageModel;

export type CalculationsUnemploymentPayListError = string[];

export type CalculationsVatCreateData = ResponseVatCalculation;

export type CalculationsVatCreateError = string[];

export type CalculationsVatListData = VatCalculationPageModel;

export type CalculationsVatListError = string[];

export type CampaignContentcampaignsDetailDetailData = ResponseContentCampaign;

export type CampaignContentcampaignsDetailDetailError = string[];

export type CampaignContentcampaignsListListData = ContentCampaignsListModel;

export type CampaignContentcampaignsListListError = string[];

export type CampaignDetailDetailData = CreditCardCampaignDetailModel;

export type CampaignDetailDetailError = string[];

export type CampaignDetailFriendlyUrlListData = CreditCardCampaignDetailModel;

export type CampaignDetailFriendlyUrlListError = string[];

export interface CampaignDetailFriendlyUrlListParams {
  friendlyUrl?: string;
}

export enum CaptchaType {
  V2 = 'v2',
  V3 = 'v3',
}

export interface CareerPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  openPositions?: ResponseOpenPosition[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CategoryDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  articles?: ResponseStoreFrontCategoryArticleModelListPagedApiResponse;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categories?: ResponseStoreFrontCategoryList[] | null;
  category?: ResponseStoreFrontCategoryDetailModel;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export enum CategoryEnumType {
  None = 'None',
  ConsumerLoan = 'ConsumerLoan',
  Deposit = 'Deposit',
  HousingLoan = 'HousingLoan',
  VehicleLoan = 'VehicleLoan',
  SmeLoan = 'SmeLoan',
  CreditCard = 'CreditCard',
}

export type CategoryListData = any;

export interface ChatBotListResponseModel {
  chatBots?: ChatBotResponseModel[] | null;
}

export interface ChatBotResponseModel {
  allowAllPage?: boolean;
  device?: DeviceType;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  scriptContent?: string | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface CheckImageProcessingResultModel {
  accountNumber?: string | null;
  /** @format int32 */
  bankId?: number;
  branchCode?: string | null;
  checkNumber?: string | null;
  identityNumber?: string | null;
}

export interface CheckPromissoryApplyPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  applyBanks?: ResponseBank[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  cities?: ResponseCity[] | null;
  currencyTypeCodes?: StringInt32KeyValuePair[] | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  drawerBankId?: number;
  drawerBankName?: string | null;
  hasSaved?: boolean;
  /** @format double */
  maxAmount?: number;
  /** @format double */
  minAmount?: number;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CheckPromissoryApplySuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  applyBanks?: Record<string, string>;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  drawerBankId?: number;
  drawerBankName?: string | null;
  hasSaved?: boolean;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CheckPromissoryHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  anyOffer?: boolean;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  currencyTypeCodes?: StringInt32KeyValuePair[] | null;
  /** @format double */
  maxAmount?: number;
  /** @format double */
  minAmount?: number;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export type CheckpromissoryApplyFormCreateData = CheckPromissoryApplySuccessPageModel;

export type CheckpromissoryApplyFormCreateError = string[];

export interface CheckpromissoryApplyFormCreatePayload {
  AccountNumber?: string;
  AdwordsCampaign?: string;
  /** @format double */
  Amount?: number;
  Banks?: Record<string, string>;
  /** @format date-time */
  BirthDate?: string;
  BranchCode?: string;
  BuyerTaxNo?: string;
  BuyerTitle?: string;
  /** @format binary */
  CheckBackFace?: File;
  /** @format date-time */
  CheckDate?: string;
  /** @format binary */
  CheckFrontFace?: File;
  CheckNumber?: string;
  ComponentType?: ComponentType;
  Currency?: CurrencyCodeType;
  /** @format int32 */
  DrawerBankId?: number;
  DrawerBankName?: string;
  DrawerIdentityNumber?: string;
  DrawerTitle?: string;
  Email?: string;
  ErrorMessage?: string;
  FactoringTransactionNumber?: string;
  FirstName?: string;
  GoogleClickId?: string;
  GoogleId?: string;
  HasEndorsement?: boolean;
  HashCode?: string;
  IdentityNumber?: string;
  /** @format binary */
  InvoiceFrontFace?: File;
  IpAddress?: string;
  IsSubscribe?: boolean;
  LastName?: string;
  OtpCode?: string;
  PhoneNumber?: string;
  /** @format int32 */
  ProductId?: number;
  ProductName?: string;
  ReferrerUrl?: string;
  /** @format int32 */
  UserId?: number;
  UserTrackingId?: string;
}

export type CheckpromissoryApplyFormListData = CheckPromissoryApplyPageModel;

export type CheckpromissoryApplyFormListError = string[];

export interface CheckpromissoryApplyFormListParams {
  /** @format double */
  amount?: number;
  /** @format date-time */
  checkDate?: string;
}

export type CheckpromissoryCheckBankListData = ResponseBanks;

export type CheckpromissoryCheckBankListError = string[];

export interface CheckpromissoryCheckBankListParams {
  IsCheque?: boolean;
  IsFactoringIntegrator?: boolean;
}

export type CheckpromissoryHomeListData = CheckPromissoryHomePageModel;

export type CheckpromissoryHomeListError = string[];

export interface CheckpromissoryHomeListParams {
  ChannelCampaignCode?: string;
  /** @format double */
  Amount?: number;
  /** @format date-time */
  CheckDate?: string;
}

export type CheckpromissoryImageProcessingCreateData = CheckImageProcessingResultModel;

export type CheckpromissoryImageProcessingCreateError = string[];

export interface CheckpromissoryImageProcessingCreatePayload {
  /** @format binary */
  CheckFrontFace?: File;
}

export type CheckpromissoryListListData = ResponseRecourse[];

export type CheckpromissoryListListError = string[];

export interface CheckpromissoryListListParams {
  RecourseProcessType?: RecourseProcessType;
  /** @format int32 */
  ParentId?: number;
  /** @format int32 */
  UserId?: number;
  PhoneNumber?: string;
}

export enum CommentStatusType {
  WaitingForApproval = 'WaitingForApproval',
  Approved = 'Approved',
  Replied = 'Replied',
  Denied = 'Denied',
  Blocked = 'Blocked',
  AdminComment = 'AdminComment',
}

export type CommercialvehicleloanDetailListData = boolean;

export type CommercialvehicleloanDetailListError = string[];

export interface CommercialvehicleloanDetailListParams {
  BankSeoUrl?: string;
  ProductSeoUrl?: string;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  /** @format int32 */
  CarStatus?: number;
  /** @format int32 */
  VehicleModelId?: number;
  ChannelCampaignCode?: string;
}

export type CommercialvehicleloanHomeListData = VehicleLoanHomePageModel;

export type CommercialvehicleloanHomeListError = string[];

export type CommercialvehicleloanListDetailData = VehicleLoanBankListViewModel;

export type CommercialvehicleloanListDetailError = string[];

export interface CommercialvehicleloanListDetailParams {
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  /** @format int32 */
  CarStatus?: number;
  /** @format int32 */
  VehicleModelId?: number;
  ChannelCampaignCode?: string;
  bankSeoUrl: string;
}

export type CommercialvehicleloanListListData = VehicleLoanListViewModel;

export type CommercialvehicleloanListListError = string[];

export interface CommercialvehicleloanListListParams {
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  PopularRedirect?: boolean;
  /** @format int32 */
  Count?: number;
  /** @format int32 */
  CarStatus?: number;
  /** @format int32 */
  VehicleModelId?: number;
  ChannelCampaignCode?: string;
}

export type CommercialvehicleloanRecourseCreateData = VehicleLoanSuccessPageModel;

export type CommercialvehicleloanRecourseCreateError = string[];

export type CommercialvehicleloanRecourseFormDetailData = VehicleLoanFormModel;

export type CommercialvehicleloanRecourseFormDetailError = string[];

export interface CommercialvehicleloanRecourseFormDetailParams {
  /** @format double */
  amount?: number;
  /** @format int32 */
  maturity?: number;
  isActiveRecourse?: boolean;
  /** @format int32 */
  productId: number;
}

export type CommercialvehicleloanRecourseForwardDetailData = VehicleLoanForwardModel;

export type CommercialvehicleloanRecourseForwardDetailError = string[];

export interface CommercialvehicleloanRecourseForwardDetailParams {
  Component?: ComponentType;
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelCampaignId?: number;
  /** @format int32 */
  RecourseId?: number;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  /** @format int32 */
  productId: number;
}

export enum ComponentType {
  All = 'All',
  Modules = 'Modules',
  List = 'List',
  Detail = 'Detail',
  Score = 'Score',
}

export type ConfigurationCreateData = any;

export type ConfigurationListData = any;

export interface ContactPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface ContentCampaignsListModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  contentCampaigns?: ResponseContentCampaignBasic[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export type ContentCarModelYearsDetailData = any;

export type ContentCarModelsDetailData = any;

export type ContentChatbotsListData = ChatBotListResponseModel;

export interface ContentChatbotsListParams {
  PageUrl?: string;
}

export type ContentDistrictsDetailData = any;

export type ContentHellobarsListData = ResponseHelloBar;

export interface ContentHellobarsListParams {
  PageUrl?: string;
}

export type ContentOccupationtitlesDetailData = any;

/** @format int32 */
export type ContentOrientationurlForwardCreateData = number;

export type ContentOrientationurlForwardCreateError = string[];

export type ContentOrientationurlForwardDetailData = OrientationUrlForwardModel;

export type ContentOrientationurlForwardDetailError = string[];

export interface ContentOrientationurlForwardDetailParams {
  channelCampaignCode?: string;
  /** @format int32 */
  urlId: number;
}

export type ContentOrientationurlSendmailCreateError = string[];

export type ContentRedirectionListData = any;

export interface ContentRedirectionListParams {
  sourceUrl?: string;
}

export type ContentRedirectionsListData = any;

export interface CorporateTaxCalculationPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  taxationPeriods?: ResponseAttribute;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardCampaignDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaign?: ResponseCampaign;
  creditCardCampaignInfo?: ResponseCreditCardCampaignInfo;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardCampaignsModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseCreditCardCampaignListPagedApiResponse;
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  docTotal?: number;
  /** @format int32 */
  limit?: number;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  alternateCreditCards?: ResponseModuleStoreFront;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  featuredCampaigns?: ResponseStoreFeaturedCampaign;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  productInfo?: ResponseStoreFrontCreditCardDetail;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForward?: ResponseStoreFrontRecourseForward;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBankLogo[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categories?: ResponseCreditCardCategory[] | null;
  hasCommercialCard?: boolean;
  lastNews?: ResponseLastNews[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  stories?: ResponseStoreFrontCreditCardHomeStory;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardListModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseCreditCardCampaignListPagedApiResponse;
  /** @format int32 */
  currentPage?: number;
  facets?: ResponseStoreFrontProductSearchFacet;
  /** @format int32 */
  limit?: number;
  nativeCards?: ResponseNativeCard[] | null;
  otherProducts?: ResponseProductSearch[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  products?: ResponseProductSearch[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface CreditCardSuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  annualFee?: number;
  annualFeeText?: string | null;
  apiResponse?: ResponseBankIntegration;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format int32 */
  displayOrder?: number;
  isBankIntegration?: boolean;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export type CreditcardCampaignsCreateData = CreditCardCampaignsModel;

export type CreditcardCampaignsCreateError = string[];

export type CreditcardCampaignsDetailData = CreditCardCampaignDetailModel;

export type CreditcardCampaignsDetailError = string[];

export type CreditcardDetailData = CreditCardListModel;

export type CreditcardDetailError = string[];

export type CreditcardDetailListData = CreditCardDetailModel;

export type CreditcardDetailListError = string[];

export interface CreditcardDetailListParams {
  bankSeoUrl?: string;
  productSeoUrl?: string;
  channelCampaignCode?: string;
}

export interface CreditcardDetailParams {
  channelCampaignCode?: string;
  bankSeoUrl: string;
}

export type CreditcardHomeListData = CreditCardHomePageModel;

export type CreditcardHomeListError = string[];

export type CreditcardListCreateData = CreditCardListModel;

export type CreditcardListCreateError = string[];

export type CreditcardRecourseCreateData = CreditCardSuccessPageModel;

export type CreditcardRecourseCreateError = string[];

export type CreditcardRecourseFormDetailData = CreditCardForwardModel;

export type CreditcardRecourseFormDetailError = string[];

export type CreditcardRecourseForwardDetailData = CreditCardForwardModel;

export type CreditcardRecourseForwardDetailError = string[];

export interface CreditcardRecourseForwardDetailParams {
  /** @format int32 */
  ProductId?: number;
  Component?: ComponentType;
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelId?: number;
  /** @format int32 */
  RecourseId?: number;
  /** @format int32 */
  productId: number;
}

export enum CurrencyCodeType {
  TL = 'TL',
  USD = 'USD',
  AUD = 'AUD',
  DKK = 'DKK',
  SEK = 'SEK',
  CHF = 'CHF',
  JPY = 'JPY',
  CAD = 'CAD',
  KWD = 'KWD',
  NOK = 'NOK',
  GBP = 'GBP',
  EUR = 'EUR',
  RON = 'RON',
  AED = 'AED',
  RUB = 'RUB',
  SAR = 'SAR',
  BGN = 'BGN',
  IRR = 'IRR',
  CNY = 'CNY',
  PKR = 'PKR',
  ETL = 'ETL',
}

export enum CurrencyType {
  TL = 'TL',
  Dollar = 'Dollar',
  Euro = 'Euro',
}

export interface CustomResponseCampaign {
  /** @format int32 */
  bankId?: number | null;
  bankName?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isButtonActive?: boolean;
  isContract?: boolean;
  isQrPopUpActive?: boolean;
  mobileImagePath?: string | null;
  name?: string | null;
  productDetailLink?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  shortDescription?: string | null;
  /** @format date-time */
  startDate?: string | null;
}

export type CustomerNewslettersubscribesCreateData = ResponseSubscribe;

export type CustomerNewslettersubscribesCreateError = string[];

export type CustomerSubscribesCreateData = ResponseSubscribe;

export type CustomerSubscribesCreateError = string[];

export enum DeviceType {
  All = 'All',
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  MobileApp = 'MobileApp',
}

export enum DeviceTypeFlag {
  All = 'All',
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  MobileApp = 'MobileApp',
}

export interface DigitalOnBoardingCampaignDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaign?: ResponseCampaign;
  digitalOnBoardingCampaignInfo?: ResponseDigitalOnBoardingCampaignInfo;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  product?: ResponseCampaignProductDetail;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface DigitalOnBoardingCampaignListModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: CustomResponseCampaign[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  product?: ResponseCampaignProductDetail;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface DigitalOnBoardingListPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  docTotal?: number;
  facets?: ResponseProductSearchFacet[] | null;
  /** @format int32 */
  limit?: number;
  nativeCards?: ResponseNativeCard[] | null;
  otherProducts?: ResponseBankDigitalOnBoardingInfo[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  products?: ResponseBankDigitalOnBoardingInfo[] | null;
  seoInfo?: ResponseSeoInfo;
  /** @format int32 */
  sliderDisplayOrder?: number;
  sliders?: ResponseSlider[] | null;
  widgets?: ResponseWidget[] | null;
}

export interface DigitalOnboardingDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categoryCampaign?: CustomResponseCampaign[] | null;
  creditCard?: ResponseProductSearch[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  posProduct?: PosProductBankProductsListModel[] | null;
  productInfo?: ResponseStoreFrontDigitalOnboardingDetail;
  seoInfo?: ResponseSeoInfo;
  smeLoan?: ResponseSmeLoanProductBank[] | null;
  widgets?: ResponseWidget[] | null;
}

export interface DigitalOnboardingForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForward?: ResponseDigitalOnboardingRecourseForward;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export enum DigitalOnboardingOrderEnum {
  Default = 'Default',
  PromotionQuantity = 'PromotionQuantity',
  BanksName = 'BanksName',
  OfferOrder = 'OfferOrder',
  DailyWithdrawalLimit = 'DailyWithdrawalLimit',
}

export type DigitalonboardingCampaignsDetailDetailData = DigitalOnBoardingCampaignDetailModel;

export type DigitalonboardingCampaignsDetailDetailError = string[];

export interface DigitalonboardingCampaignsDetailDetailParams {
  /** @format int32 */
  id?: number;
  campaignSeoUrl: string;
}

export type DigitalonboardingCampaignsListDetailData = DigitalOnBoardingCampaignListModel;

export type DigitalonboardingCampaignsListDetailError = string[];

export type DigitalonboardingDetailDetailData = DigitalOnboardingDetailModel;

export type DigitalonboardingDetailDetailError = string[];

export interface DigitalonboardingDetailDetailParams {
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelId?: number;
  /** @format int32 */
  ChannelCampaignId?: number;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  productSeoUrl: string;
}

export type DigitalonboardingListCreateData = DigitalOnBoardingListPageModel;

export type DigitalonboardingListCreateError = string[];

export type DigitalonboardingRecourseCreateData = ResponseDigitalOnBoardingRecourse;

export type DigitalonboardingRecourseCreateError = string[];

export type DigitalonboardingRecourseForwardDetailData = DigitalOnboardingForwardModel;

export type DigitalonboardingRecourseForwardDetailError = string[];

export interface DigitalonboardingRecourseForwardDetailParams {
  Component?: ComponentType;
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelCampaignId?: number;
  /** @format int32 */
  RecourseId?: number;
  /** @format int32 */
  productId: number;
}

export type DijikolayintegrationinfoListData = any;

export enum DomainType {
  HangiKredi = 'HangiKredi',
  HangiKrediKobi = 'HangiKrediKobi',
}

export interface ErrorModel {
  code?: string | null;
  message?: string | null;
  outerMessage?: string | null;
}

export interface FileAggregateRoute {
  aggregator?: string | null;
  /** @format int32 */
  priority?: number;
  routeIsCaseSensitive?: boolean;
  routeKeys?: string[] | null;
  routeKeysConfig?: AggregateRouteConfig[] | null;
  upstreamHost?: string | null;
  upstreamHttpMethod?: string[] | null;
  upstreamPathTemplate?: string | null;
}

export interface FileAuthenticationOptions {
  allowedScopes?: string[] | null;
  authenticationProviderKey?: string | null;
}

export interface FileCacheOptions {
  region?: string | null;
  /** @format int32 */
  ttlSeconds?: number;
}

export interface FileConfiguration {
  aggregates?: FileAggregateRoute[] | null;
  dynamicRoutes?: FileDynamicRoute[] | null;
  globalConfiguration?: FileGlobalConfiguration;
  routes?: FileRoute[] | null;
}

export interface FileDynamicRoute {
  downstreamHttpVersion?: string | null;
  rateLimitRule?: FileRateLimitRule;
  serviceName?: string | null;
}

export interface FileGlobalConfiguration {
  baseUrl?: string | null;
  downstreamHttpVersion?: string | null;
  downstreamScheme?: string | null;
  httpHandlerOptions?: FileHttpHandlerOptions;
  loadBalancerOptions?: FileLoadBalancerOptions;
  qoSOptions?: FileQoSOptions;
  rateLimitOptions?: FileRateLimitOptions;
  requestIdKey?: string | null;
  serviceDiscoveryProvider?: FileServiceDiscoveryProvider;
}

export interface FileHostAndPort {
  host?: string | null;
  /** @format int32 */
  port?: number;
}

export interface FileHttpHandlerOptions {
  allowAutoRedirect?: boolean;
  /** @format int32 */
  maxConnectionsPerServer?: number;
  useCookieContainer?: boolean;
  useProxy?: boolean;
  useTracing?: boolean;
}

export interface FileLoadBalancerOptions {
  /** @format int32 */
  expiry?: number;
  key?: string | null;
  type?: string | null;
}

export interface FileQoSOptions {
  /** @format int32 */
  durationOfBreak?: number;
  /** @format int32 */
  exceptionsAllowedBeforeBreaking?: number;
  /** @format int32 */
  timeoutValue?: number;
}

export interface FileRateLimitOptions {
  clientIdHeader?: string | null;
  disableRateLimitHeaders?: boolean;
  /** @format int32 */
  httpStatusCode?: number;
  quotaExceededMessage?: string | null;
  rateLimitCounterPrefix?: string | null;
}

export interface FileRateLimitRule {
  clientWhitelist?: string[] | null;
  enableRateLimiting?: boolean;
  /** @format int64 */
  limit?: number;
  period?: string | null;
  /** @format double */
  periodTimespan?: number;
}

export interface FileRoute {
  addClaimsToRequest?: Record<string, string>;
  addHeadersToRequest?: Record<string, string>;
  addQueriesToRequest?: Record<string, string>;
  authenticationOptions?: FileAuthenticationOptions;
  changeDownstreamPathTemplate?: Record<string, string>;
  dangerousAcceptAnyServerCertificateValidator?: boolean;
  delegatingHandlers?: string[] | null;
  downstreamHeaderTransform?: Record<string, string>;
  downstreamHostAndPorts?: FileHostAndPort[] | null;
  downstreamHttpMethod?: string | null;
  downstreamHttpVersion?: string | null;
  downstreamPathTemplate?: string | null;
  downstreamScheme?: string | null;
  fileCacheOptions?: FileCacheOptions;
  httpHandlerOptions?: FileHttpHandlerOptions;
  key?: string | null;
  loadBalancerOptions?: FileLoadBalancerOptions;
  /** @format int32 */
  priority?: number;
  qoSOptions?: FileQoSOptions;
  rateLimitOptions?: FileRateLimitRule;
  requestIdKey?: string | null;
  routeClaimsRequirement?: Record<string, string>;
  routeIsCaseSensitive?: boolean;
  securityOptions?: FileSecurityOptions;
  serviceName?: string | null;
  serviceNamespace?: string | null;
  /** @format int32 */
  timeout?: number;
  upstreamHeaderTransform?: Record<string, string>;
  upstreamHost?: string | null;
  upstreamHttpMethod?: string[] | null;
  upstreamPathTemplate?: string | null;
}

export interface FileSecurityOptions {
  ipAllowedList?: string[] | null;
  ipBlockedList?: string[] | null;
}

export interface FileServiceDiscoveryProvider {
  configurationKey?: string | null;
  host?: string | null;
  namespace?: string | null;
  /** @format int32 */
  pollingInterval?: number;
  /** @format int32 */
  port?: number;
  scheme?: string | null;
  token?: string | null;
  type?: string | null;
}

export type FinancialHealthListData = FinancialHealthPageModel;

export type FinancialHealthListError = string[];

export interface FinancialHealthPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  widgets?: ResponseWidget[] | null;
}

export type FinancialScoreListData = FinancialScorePageModel;

export type FinancialScoreListError = string[];

export interface FinancialScorePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  widgets?: ResponseWidget[] | null;
}

export interface GrossToNetCalculationPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface GuidBaseResponseJsonModel {
  /** @format uuid */
  data?: string;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export type HomeListData = HomePageModel;

export type HomeListError = string[];

export interface HomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseStoreAccountCampaign[] | null;
  maturities?: ResponseAttribute;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export enum InterestRateChangeGraficData {
  TCMB = 'TCMB',
  HangiKredi = 'HangiKredi',
}

export enum LayoutSizeType {
  None = 'None',
  Small = 'Small',
  Medium = 'Medium',
}

export type LinkListData = any;

export interface LinkListParams {
  category?: string;
}

export enum LinkTypeEnum {
  InSite = 'InSite',
  OutSite = 'OutSite',
}

export enum LoginType {
  Phone = 'Phone',
  Email = 'Email',
}

export type LookupBanksBranchesDetailData = any;

export interface LookupBanksBranchesDetailParams {
  /** @format int32 */
  districtId?: number;
  /** @format int32 */
  bankId: number;
}

export type LookupBanksListData = any;

export type LookupCitiesDistrictsDetailData = any;

export type LookupCitiesListData = any;

export type LookupCompanySectorsListData = ResponseAttribute;

export type LookupCompanySectorsListError = string[];

export type LookupCompanyTypesListData = ResponseAttribute;

export type LookupCompanyTypesListError = string[];

export enum MaturityType {
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export enum MenuDisplayDeviceType {
  Web = 'Web',
  App = 'App',
}

export enum MenuItemType {
  Bottom = 'Bottom',
  Sidebar = 'Sidebar',
  Header = 'Header',
  Hamburger = 'Hamburger',
  Footer = 'Footer',
  Metro = 'Metro',
  CustomMixMenu = 'CustomMixMenu',
  FinancialHealth = 'FinancialHealth',
  SmeCalculationTools = 'SmeCalculationTools',
}

export enum MenuType {
  Main = 'Main',
  Sub = 'Sub',
}

export type MenuitemListListData = ResponseMenuItemList;

export type MenuitemListListError = string[];

export interface MenuitemListListParams {
  itemType?: MenuItemType;
}

export type MerchantByinterestrateCalculateListData = ResponseStoreCalculateByInterestRate;

export type MerchantByinterestrateCalculateListError = string[];

export interface MerchantByinterestrateCalculateListParams {
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  /** @format double */
  InterestRate?: number;
}

export type MerchantHomeListData = MerchantHomePageModel;

export type MerchantHomeListError = string[];

export interface MerchantHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  maturities?: ResponseAttribute;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export interface MiniResponseAttribute {
  code?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  optionValue?: string | null;
  options?: MiniResponseAttributeOption[] | null;
  placeHolder?: string | null;
}

export interface MiniResponseAttributeOption {
  code?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface MonthlyIncome {
  label?: string | null;
  /** @format int32 */
  value?: number;
}

export enum MonthlyIncomeType {
  None = 'None',
  Volume1 = 'Volume1',
  Volume2 = 'Volume2',
  Volume3 = 'Volume3',
}

export interface MonthlyPayment {
  /** @format double */
  balance?: number;
  /** @format double */
  bsmv?: number;
  /** @format int32 */
  currentMaturity?: number;
  /** @format double */
  interest?: number;
  /** @format double */
  kkdf?: number;
  /** @format double */
  mainAmount?: number;
  /** @format double */
  monthlyInstallment?: number;
}

export enum NativeCardAttributeTypeEnum {
  AmountRange = 'AmountRange',
  Score = 'Score',
  Category = 'Category',
}

export enum NativeCardRedirectionType {
  ProductRedirection = 'ProductRedirection',
  LinkRedirection = 'LinkRedirection',
}

export enum NativeCardTypeEnum {
  Image = 'Image',
  Product = 'Product',
}

export enum OperatingSystemType {
  All = 'All',
  Android = 'Android',
  IOS = 'iOS',
  Windows = 'Windows',
}

export interface OrientationUrlForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  urlForward?: ResponseOrientationUrlGenerator;
  widgets?: ResponseWidget[] | null;
}

export enum OtpSubjectType {
  Signup = 'Signup',
  ResetPassword = 'ResetPassword',
  RemoveAccount = 'RemoveAccount',
  RemoveAccountInfo = 'RemoveAccountInfo',
}

export enum OtpType {
  Sms = 'Sms',
  Email = 'Email',
}

export interface OurTeamPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  teamMembersResponse?: ResponseTeamMemberStoreFront[] | null;
  widgets?: ResponseWidget[] | null;
}

export type OutputcacheDeleteData = any;

export type PagesDetailData = ResponseModuleStoreFront;

export type PagesDetailError = string[];

export interface PagesDetailParams {
  /** @format int32 */
  TypeId?: number;
  NewFeature?: string;
  /** @format int32 */
  pageId: number;
}

export interface PosProductBankListViewModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  facets?: ResponseStoreFrontProductSearchFacet[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  products?: ResponseStoreFrontPosProduct[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductBankProductsListModel {
  activityStatus?: ProductActivityStatusTypeEnum;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  blockedPeriod?: string | null;
  campaigns?: PosProductCampaignsModel[] | null;
  commissions?: PosProductCommissionsModel[] | null;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  dynamicTags?: string | null;
  highlightText?: string | null;
  /** @format int32 */
  id?: number;
  interestRateText?: string | null;
  isActiveRecourse?: boolean;
  isVariableInterest?: boolean;
  /** @format double */
  mininumRate?: number;
  name?: string | null;
  productType?: ProductType;
  productTypeText?: string | null;
  recourseLink?: string | null;
  recourseStatus?: RecourseStatusType;
  seoUrl?: string | null;
  /** @format double */
  subscription?: number | null;
  subscriptionPeriod?: PosSubscriptionPeriodEnum;
  type?: PosTypeEnum;
}

export interface PosProductCampaignsModel {
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  productId?: number;
  title?: string | null;
  type?: PosProductInfoCampaignType;
}

export interface PosProductCommissionsModel {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  installment?: number;
  /** @format int32 */
  productId?: number;
  /** @format double */
  rate?: number;
  rateText?: string | null;
}

export interface PosProductCompanyFormModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  cities?: ResponseCity[] | null;
  monthlyIncomeType?: MonthlyIncomeType;
  monthlyIncomeTypeText?: string | null;
  monthlyIncomes?: MonthlyIncome[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForm?: ResponseStoreFrontPosProductForm;
  seoInfo?: ResponseSeoInfo;
  webSiteAddress?: string | null;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseStoreAccountCampaign[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  productInfo?: ResponseStoreFrontPosProductDetail;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductFormModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  cities?: ResponseCity[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForm?: ResponseStoreFrontPosProductForm;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForward?: ResponsePosProductRecourseForward;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export enum PosProductInfoCampaignType {
  Information = 'Information',
  Campaign = 'Campaign',
}

export interface PosProductListPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  facets?: ResponseStoreFrontProductSearchFacet[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  products?: ResponseStoreFrontPosProduct[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface PosProductSuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format double */
  commissionRate?: number;
  /** @format int32 */
  displayOrder?: number;
  isActiveRecourse?: boolean;
  isSponsorBank?: boolean;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  /** @format double */
  subscription?: number;
  subscriptionPeriod?: PosSubscriptionPeriodEnum;
  subscriptionText?: string | null;
  widgets?: ResponseWidget[] | null;
}

export enum PosSubscriptionPeriodEnum {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export enum PosTypeEnum {
  Physical = 'Physical',
  Virtual = 'Virtual',
  Digital = 'Digital',
  Mobile = 'Mobile',
}

export type PosproductBanksListData = ResponseStoreFrontBank[];

export type PosproductBanksListError = string[];

export type PosproductDetailData = PosProductBankListViewModel;

export type PosproductDetailDetailData = PosProductDetailModel;

export type PosproductDetailDetailError = string[];

export interface PosproductDetailDetailParams {
  ChannelCampaignCode?: string;
  bankSeoUrl: string;
  productSeoUrl: string;
}

export type PosproductDetailError = string[];

export type PosproductDetailListData = PosProductDetailModel;

export type PosproductDetailListError = string[];

export interface PosproductDetailListParams {
  BankSeoUrl?: string;
  ProductSeoUrl?: string;
  ChannelCampaignCode?: string;
}

export interface PosproductDetailParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  PosType?: PosTypeEnum[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
  bankSeoUrl: string;
}

export type PosproductDigitalListData = PosProductListPageModel;

export type PosproductDigitalListError = string[];

export interface PosproductDigitalListParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  Bank?: number[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
}

export type PosproductHomeListData = PosProductHomePageModel;

export type PosproductHomeListError = string[];

export type PosproductListListData = PosProductListPageModel;

export type PosproductListListError = string[];

export interface PosproductListListParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  Bank?: number[];
  PosType?: PosTypeEnum[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
}

export type PosproductMobileListData = PosProductListPageModel;

export type PosproductMobileListError = string[];

export interface PosproductMobileListParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  Bank?: number[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
}

export type PosproductPhysicalListData = PosProductListPageModel;

export type PosproductPhysicalListError = string[];

export interface PosproductPhysicalListParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  Bank?: number[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
}

export type PosproductRecourseCompanyFormDetailData = PosProductCompanyFormModel;

export type PosproductRecourseCompanyFormDetailError = string[];

export type PosproductRecourseCreateData = PosProductSuccessPageModel;

export type PosproductRecourseCreateError = string[];

export type PosproductRecourseFormDetailData = PosProductFormModel;

export type PosproductRecourseFormDetailError = string[];

export type PosproductRecourseForwardDetailData = PosProductForwardModel;

export type PosproductRecourseForwardDetailError = string[];

export interface PosproductRecourseForwardDetailParams {
  Component?: ComponentType;
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelCampaignId?: number;
  /** @format int32 */
  RecourseId?: number;
  /** @format int32 */
  productId: number;
}

export type PosproductTasksRecourseCompanyFormMailListData = ResponsePosProductCompanyFormMailRecourse;

export type PosproductTasksRecourseCompanyFormMailListError = string[];

export interface PosproductTasksRecourseCompanyFormMailListParams {
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  ResourceStatusType?: RecourseStatusType;
}

export type PosproductTasksRecoursemailListData = ResponsePosProductRecourseMail;

export type PosproductTasksRecoursemailListError = string[];

export interface PosproductTasksRecoursemailListParams {
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  BankCode?: string;
}

export type PosproductVirtualListData = PosProductListPageModel;

export type PosproductVirtualListError = string[];

export interface PosproductVirtualListParams {
  /** @format int32 */
  Count?: number;
  Component?: ComponentType;
  Bank?: number[];
  ChannelCampaignCode?: string;
  Order?: ProductSearchOrder;
}

export enum ProductActivityStatusTypeEnum {
  All = 'All',
  IsActiveOnList = 'IsActiveOnList',
  IsActiveOnBankPage = 'IsActiveOnBankPage',
  IsActiveOnNewYearPage = 'IsActiveOnNewYearPage',
  IsActiveOnEducationPage = 'IsActiveOnEducationPage',
  IsActiveOnSmePage = 'IsActiveOnSmePage',
  IsActiveOnMarriagePage = 'IsActiveOnMarriagePage',
  IsActiveOnHolidayPage = 'IsActiveOnHolidayPage',
  IsActiveOnShoppingPage = 'IsActiveOnShoppingPage',
  IsActiveOnConscriptionPage = 'IsActiveOnConscriptionPage',
  IsActiveOnItemPage = 'IsActiveOnItemPage',
  IsActiveOnHypothecaryPage = 'IsActiveOnHypothecaryPage',
  IsActiveOnVocationalPage = 'IsActiveOnVocationalPage',
  IsActiveOnHealthPage = 'IsActiveOnHealthPage',
  IsActiveOnFestalPage = 'IsActiveOnFestalPage',
}

export enum ProductEnum {
  None = 'None',
  ConsumerLoan = 'ConsumerLoan',
  Deposit = 'Deposit',
  HousingLoan = 'HousingLoan',
  VehicleLoan = 'VehicleLoan',
  SmeLoan = 'SmeLoan',
  CreditCard = 'CreditCard',
  DigitalOnboarding = 'DigitalOnboarding',
  Pos = 'Pos',
  RetirementBanking = 'RetirementBanking',
  Investment = 'Investment',
}

export enum ProductEnumType {
  Month = 'Month',
  Sponsor = 'Sponsor',
  Normal = 'Normal',
  SpecialRate = 'SpecialRate',
  CustomizableProductWithFrame = 'CustomizableProductWithFrame',
  CustomizableProductWithoutFrame = 'CustomizableProductWithoutFrame',
  LowestInterestRate = 'LowestInterestRate',
}

export enum ProductSearchOrder {
  Default = 'Default',
  CommissionIncreasing = 'CommissionIncreasing',
  CommissionDecreasing = 'CommissionDecreasing',
  GeneralOrder = 'GeneralOrder',
  PosTypeOrder = 'PosTypeOrder',
}

export enum ProductType {
  ConsumerLoan = 'ConsumerLoan',
  Deposit = 'Deposit',
  HousingLoan = 'HousingLoan',
  VehicleLoan = 'VehicleLoan',
  SmeLoan = 'SmeLoan',
  CreditCard = 'CreditCard',
  Pos = 'Pos',
  SmeVehicleLoan = 'SmeVehicleLoan',
}

export enum ProductTypeEnum {
  ConsumerLoan = 'ConsumerLoan',
  Deposit = 'Deposit',
  HousingLoan = 'HousingLoan',
  VehicleLoan = 'VehicleLoan',
  SmeLoan = 'SmeLoan',
  CreditCard = 'CreditCard',
  DigitalOnboarding = 'DigitalOnboarding',
  Pos = 'Pos',
  RetirementBanking = 'RetirementBanking',
  SmeVehicleLoan = 'SmeVehicleLoan',
}

export interface ProfilePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export enum RecourceVerificationStatus {
  None = 'None',
  Otp = 'Otp',
}

export enum RecourseProcessType {
  WaitingApprove = 'WaitingApprove',
  Approved = 'Approved',
  Rejected = 'Rejected',
  SentToBank = 'SentToBank',
}

export enum RecourseStatusType {
  None = 'None',
  Form = 'Form',
  Redirect = 'Redirect',
  CompanyForm = 'CompanyForm',
}

export enum RecourseStatusTypeEnum {
  None = 'None',
  Form = 'Form',
  Redirect = 'Redirect',
}

export interface RequestAuthSignin {
  clientId?: string | null;
  clientIp?: string | null;
  clientSecret?: string | null;
  deviceId?: string | null;
  grantType?: string | null;
  loginType?: LoginType;
  password?: string | null;
  scopes?: string[] | null;
  token?: string | null;
  username?: string | null;
  version?: CaptchaType;
}

export interface RequestAuthSignup {
  /** @format date-time */
  birthDate?: string;
  /** @format int32 */
  cityId?: number;
  /** @format int32 */
  companySectorId?: number;
  companyTitle?: string | null;
  /** @format int32 */
  companyTypeId?: number;
  deviceId?: string | null;
  /** @format int32 */
  districtId?: number;
  email?: string | null;
  /** @format int32 */
  financialYearStart?: number;
  identityNumber?: string | null;
  ipAddress?: string | null;
  isConsentText?: boolean;
  isLightingText?: boolean;
  isUserAgreementText?: boolean;
  jobTitle?: string | null;
  name?: string | null;
  otpType?: OtpType;
  password?: string | null;
  phoneNumber?: string | null;
  surname?: string | null;
  token?: string | null;
  /** @format uuid */
  userTrackingId?: string | null;
  version?: CaptchaType;
}

export interface RequestChangeEmail {
  currentEmail?: string | null;
  newEmail?: string | null;
}

export interface RequestChangePassword {
  currentPassword?: string | null;
  newPassword?: string | null;
}

export interface RequestCompany {
  /** @format int32 */
  cityId?: number;
  /** @format int32 */
  districtId?: number;
  /** @format int32 */
  financialYearStart?: number;
  /** @format int32 */
  sectorId?: number;
  title?: string | null;
  /** @format int32 */
  typeId?: number;
  vknTckn?: string | null;
}

export interface RequestCreditCardCampaignSearch {
  /** @format int32 */
  bankId?: number;
  /** @format int32 */
  brandId?: number;
  /** @format int32 */
  campaignId?: number;
  /** @format int32 */
  campaignProgramId?: number;
  /** @format int32 */
  currentPage?: number;
  filters?: RequestProductSearchFacet[] | null;
  isCommercialProduct?: boolean;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  order?: number | null;
  /** @format int32 */
  productId?: number;
}

export interface RequestDigitalOnBoardingRecourse {
  adwordsCampaign?: string | null;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface RequestInsertComment {
  email?: string | null;
  ipAddress?: string | null;
  isPermitted?: boolean;
  message?: string | null;
  name?: string | null;
  /** @format int32 */
  subject?: number;
  subjectTitle?: string | null;
  surname?: string | null;
}

export interface RequestNewsletterSubscribe {
  email?: string | null;
  /** @format int32 */
  pageId?: number;
  phone?: string | null;
  /** @format int32 */
  userId?: number;
}

export interface RequestOrientationUrlGeneratorLog {
  adwordsCampaign?: string | null;
  /** @format int32 */
  bankId?: number;
  category?: ProductEnum;
  deviceType?: DeviceType;
  fictionName?: string | null;
  /** @format int32 */
  fictionTypeId?: number | null;
  googleClickId?: string | null;
  googleId?: string | null;
  ipAddress?: string | null;
  isActive?: boolean;
  link?: string | null;
  /** @format int32 */
  orientationUrlId?: number;
  /** @format int32 */
  pageId?: number;
  phoneNumber?: string | null;
  productCategory?: ProductEnum;
  productId?: string | null;
  productName?: string | null;
  referrerUrl?: string | null;
  /** @format int32 */
  resourceSite?: number | null;
  /** @format double */
  revenue?: number;
  title?: string | null;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface RequestOrientationUrlGeneratorMail {
  id?: string | null;
  path?: string | null;
  referrerUrl?: string | null;
  userAgent?: string | null;
}

export interface RequestOtpSend {
  otpSubjectType?: OtpSubjectType;
  otpType?: OtpType;
  target?: string | null;
}

export interface RequestPasswordReset {
  otpType?: OtpType;
  token?: string | null;
  username?: string | null;
  version?: CaptchaType;
}

export interface RequestPosProductRecourse {
  adwordsCampaign?: string | null;
  /** @format date-time */
  birthDate?: string | null;
  /** @format int32 */
  cityId?: number | null;
  companyTitle?: string | null;
  componentType?: ComponentType;
  device?: string | null;
  /** @format int32 */
  districtId?: number | null;
  email?: string | null;
  /** @format double */
  endorsementAmount?: number | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  isContract?: boolean;
  isSubscribe?: boolean;
  lastName?: string | null;
  monthlyIncomeType?: MonthlyIncomeType;
  monthlyIncomeTypeText?: string | null;
  /** @format int32 */
  pageId?: number;
  phoneNumber?: string | null;
  /** @format double */
  price?: number;
  /** @format double */
  rate?: number;
  referrerUrl?: string | null;
  /** @format int32 */
  revenue?: number;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
  webSiteAddress?: string | null;
}

export interface RequestProductSearch {
  bankIds?: number[] | null;
  /** @format int32 */
  categoryId?: number;
  categorySeoUrl?: string | null;
  channelCampaignCode?: string | null;
  /** @format int32 */
  currentPage?: number;
  filters?: RequestProductSearchFacet[] | null;
  isCommercial?: boolean;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  order?: number | null;
}

export interface RequestProductSearchFacet {
  filterName?: string | null;
  selectedOptions?: string[] | null;
}

export interface RequestRefreshToken {
  clientId?: string | null;
  clientSecret?: string | null;
  grantType?: string | null;
  ipAddress?: string | null;
  refreshToken: string;
}

export interface RequestResetPasswordToken {
  newPassword?: string | null;
  token?: string | null;
}

export interface RequestSmeLoanListMail {
  /** @format double */
  amount?: number;
  /** @format int32 */
  bankId?: number | null;
  channelCampaignCode?: string | null;
  email?: string | null;
  /** @format int32 */
  maturity?: number;
}

export interface RequestSmeLoanRecourse {
  adwordsCampaign?: string | null;
  /** @format double */
  amount?: number;
  bankCode?: string | null;
  /** @format date-time */
  birthDate?: string;
  componentType?: ComponentType;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  /** @format double */
  income?: number | null;
  ipAddress?: string | null;
  isActiveIntegration?: boolean;
  lastName?: string | null;
  /** @format int32 */
  maturity?: number;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  referrerUrl?: string | null;
  /** @format int32 */
  resourceSite?: number | null;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface RequestSmeLoanStartTransaction {
  /** @format double */
  amount?: number;
  bankCode?: string | null;
  callbackUrl?: string | null;
  /** @format double */
  interestRate?: number;
  /** @format int32 */
  maturity?: number;
  preApprovedApplicationId?: string | null;
  /** @format int32 */
  recourseId?: number;
}

export interface RequestStoreCalculationMail {
  calculationResult?: string | null;
  calculationType?: string | null;
  email?: string | null;
}

export interface RequestStoreFrontArticleComment {
  /** @format int32 */
  articleId?: number;
  comment?: string | null;
  email?: string | null;
  fullName?: string | null;
  ipAddress?: string | null;
  /** @format int32 */
  parentCommentId?: number | null;
  status?: CommentStatusType;
  /** @format int32 */
  storeId?: number;
  /** @format int32 */
  userId?: number | null;
}

export interface RequestStoreFrontArticleVote {
  /** @format int32 */
  articleId?: number;
  ipAddress?: string | null;
  /** @format int32 */
  userId?: number | null;
  /** @format double */
  vote?: number;
}

export interface RequestStoreFrontCommentLike {
  /** @format int32 */
  commentId?: number;
  ipAddress?: string | null;
  /** @format int32 */
  storeId?: number;
}

export interface RequestStoreFrontCreditCardRecourse {
  adwordsCampaign?: string | null;
  bankCode?: string | null;
  /** @format date-time */
  birthDate?: string;
  communicationPermit?: boolean;
  componentType?: ComponentType;
  device?: DeviceType;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  isActiveIntegration?: boolean;
  lastName?: string | null;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  referrerUrl?: string | null;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
  /** @format int32 */
  workBank?: number | null;
}

export interface RequestStoreFrontRecourseSP {
  adwordsCampaign?: string | null;
  /** @format int32 */
  averageSalaryPerEmployeeType?: number;
  averageSalaryPerEmployeeTypeText?: string | null;
  banks?: Record<string, string>;
  /** @format date-time */
  birthDate?: string;
  /** @format int32 */
  cityId?: number;
  cityName?: string | null;
  companyTitle?: string | null;
  componentType?: ComponentType;
  /** @format int32 */
  countyId?: number | null;
  countyName?: string | null;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  isSubscribe?: boolean;
  lastName?: string | null;
  /** @format int32 */
  numberOfEmployees?: number;
  otpCode?: string | null;
  /** @format int32 */
  parentId?: number;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  referrerUrl?: string | null;
  /** @format double */
  totalGrossSalary?: number;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface RequestStoreFrontSeveranceAndNoticePay {
  /** @format int32 */
  annualGrossPremium?: number | null;
  /** @format int32 */
  cumulativeTaxBase?: number | null;
  /** @format date-time */
  endDateOfWork?: string;
  /** @format int32 */
  monthlyGrossAdditionalPayments?: number | null;
  /** @format int32 */
  monthlyGrossSalary?: number;
  /** @format date-time */
  startDateOfWork?: string;
}

export interface RequestStoreGrossNetSalary {
  /** @format int32 */
  calculationType?: number;
  /** @format int32 */
  disabilityLevel?: number;
  /** @format int32 */
  employeeType?: number;
  /** @format int32 */
  formMonth?: number;
  /** @format double */
  formSalary?: number;
  /** @format int32 */
  maritalStatus?: number;
  /** @format int32 */
  numberOfChildren?: number;
  /** @format int32 */
  partnerWorkStatus?: number;
  salaryByMonth?: RequestStoreSalaryByMonth[] | null;
  /** @format int32 */
  year?: number;
}

export interface RequestStoreSalaryByMonth {
  /** @format int32 */
  month?: number;
  /** @format double */
  salary?: number;
}

export interface RequestStoreUnemploymentPay {
  /** @format int32 */
  applicationYear?: number;
  daysWorked?: string | null;
  /** @format int32 */
  daysWorkedValue?: number;
  /** @format double */
  firstSalary?: number;
  /** @format double */
  fourthSalary?: number;
  /** @format double */
  secondSalary?: number;
  /** @format double */
  thirdSalary?: number;
}

export interface RequestSubscribe {
  email?: string | null;
  /** @format int32 */
  pageId?: number;
  phone?: string | null;
  /** @format int32 */
  purposeId?: number;
  /** @format int32 */
  storeId?: number;
  /** @format int32 */
  userId?: number;
}

export interface RequestVatCalculation {
  /** @format double */
  amountMoney?: number;
  /** @format int32 */
  calculationType?: number;
  /** @format double */
  vatRate?: number;
}

export interface RequestVehicleLoanRecourse {
  adwordsCampaign?: string | null;
  /** @format double */
  amount?: number;
  /** @format date-time */
  birthDate?: string | null;
  /** @format int32 */
  branchId?: number | null;
  /** @format int32 */
  brandId?: number | null;
  /** @format int32 */
  cityId?: number | null;
  communicationPermit?: boolean;
  componentType?: ComponentType;
  /** @format int32 */
  districtId?: number | null;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  isSendOtherBanks?: boolean;
  lastName?: string | null;
  /** @format int32 */
  maturity?: number;
  /** @format int32 */
  modelId?: number | null;
  /** @format int32 */
  modelYear?: number | null;
  personalDataPermit?: boolean;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  referrerUrl?: string | null;
  /** @format int32 */
  resourceSite?: number | null;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface RequestigitalOnboardingProductSearch {
  channelCampaignCode?: string | null;
  /** @format int32 */
  count?: number | null;
  /** @format int32 */
  currentPage?: number;
  filters?: RequestProductSearchFacet[] | null;
  /** @format int32 */
  limit?: number;
  order?: DigitalOnboardingOrderEnum;
}

export interface ResponseAdvertisement {
  advertisementScript?: ResponseAdvertisementDetail;
  deviceType?: DeviceType;
  elementId?: string | null;
  /** @format int32 */
  height?: number | null;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  width?: number | null;
}

export interface ResponseAdvertisementDetail {
  bodyCode?: string | null;
  headerCode?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface ResponseAttribute {
  code?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  optionValue?: string | null;
  options?: ResponseAttributeOption[] | null;
  placeHolder?: string | null;
}

export interface ResponseAttributeOption {
  value1?: string | null;
  /** @format int32 */
  attributeId?: number;
  code?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  isActive?: boolean;
  name?: string | null;
  placeHolder?: string | null;
}

export interface ResponseAuthSignin {
  companyInfos?: ResponseCompanyInfo[] | null;
  token?: TokenResponse;
  userInfo?: ResponseUserInfo;
}

export interface ResponseAuthSigninBaseResponseJsonModel {
  data?: ResponseAuthSignin;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseAuthSignup {
  email?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  phoneNumber?: string | null;
  surname?: string | null;
  token?: TokenResponse;
  /** @format uuid */
  userTrackingId?: string;
}

export interface ResponseAuthSignupBaseResponseJsonModel {
  data?: ResponseAuthSignup;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseBank {
  /** @format int32 */
  atmCount?: number | null;
  atmCountAsString?: string | null;
  bankCreditCardInfo?: ResponseBankCreditCardInfo;
  bankSeoUrl?: string | null;
  /** @format int32 */
  bankType?: number | null;
  /** @format int32 */
  branchCount?: number;
  categories?: ResponseBankCategory[] | null;
  code?: string | null;
  description?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  externalSystemId?: number;
  /** @format int32 */
  financeType?: number;
  friendlyUrl?: string | null;
  /** @format int32 */
  id?: number;
  isActive?: boolean;
  isCreditCardContract?: boolean;
  logoPath?: string | null;
  name?: string | null;
  seoUrl?: string | null;
  /** @format int32 */
  workplaceCount?: number | null;
  workplaceCountAsString?: string | null;
}

export interface ResponseBankCategory {
  categoryCode?: string | null;
  /** @format int32 */
  categoryId?: number;
  categoryName?: string | null;
  seoUrl?: string | null;
}

export interface ResponseBankCreditCardInfo {
  /** @format int32 */
  bankId?: number;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
}

export interface ResponseBankDigitalOnBoardingInfo {
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  buttonText?: string | null;
  campaigns?: ResponseBankDigitalOnBoardingInfoCampaign[] | null;
  categoryCampaigns?: ResponseCampaign[] | null;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number | null;
  featuredProductInformations?: ResponseFeaturedProductInformations[] | null;
  hasAnnualFee?: boolean;
  heroText?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActiveRecourse?: boolean;
  isButtonActive?: boolean;
  isQrPopUpActive?: boolean | null;
  /** @format int32 */
  itemIndex?: number;
  logoPath?: string | null;
  mobileRecourseLink?: string | null;
  name?: string | null;
  productDetailLink?: string | null;
  productHeroDescription?: string | null;
  productHeroTitle?: string | null;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  sponsorProduct?: boolean;
}

export interface ResponseBankDigitalOnBoardingInfoCampaign {
  description?: string | null;
  /** @format int32 */
  productId?: number;
  type?: BankDigitalOnBoardingInfoCampaignType;
}

export interface ResponseBankIntegration {
  isSuccessful?: boolean;
  /** @format int32 */
  responseId?: number;
  responseMessage?: string | null;
  /** @format int32 */
  resultCode?: number | null;
  verificationStatus?: RecourceVerificationStatus;
}

export interface ResponseBankInterestRateTable {
  interestRates?: ResponseStoreInterestRate[] | null;
  productType?: ProductTypeEnum;
  productTypeNames?: string[] | null;
}

export interface ResponseBankLogo {
  /** @format int32 */
  atmCount?: number | null;
  bankCreditCardInfo?: ResponseBankCreditCardInfo;
  bankSeoUrl?: string | null;
  categories?: ResponseBankCategory[] | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  externalSystemId?: number;
  /** @format int32 */
  financeType?: number;
  /** @format int32 */
  id?: number;
  isCreditCardContract?: boolean;
  logoPath?: string | null;
  name?: string | null;
  /** @format int32 */
  workplaceCount?: number | null;
}

export interface ResponseBankLogoModel {
  bankLogos?: ResponseBankLogo[] | null;
  type?: ProductType;
}

export interface ResponseBankOtherProduct {
  iconPath?: string | null;
  name?: string | null;
  text?: string | null;
  type?: ProductType;
  url?: string | null;
}

export interface ResponseBankOtherProductModel {
  otherProducts?: ResponseBankOtherProduct[] | null;
  otherProductsTitle?: string | null;
}

export interface ResponseBankPartners {
  bankName?: string | null;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
}

export interface ResponseBanks {
  chequeBanks?: ResponseBank[] | null;
  factoringIntegratorBanks?: ResponseBank[] | null;
}

export interface ResponseCampaign {
  /** @format int32 */
  bankId?: number | null;
  bankName?: string | null;
  /** @format int32 */
  categoryId?: number;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isButtonActive?: boolean;
  isContract?: boolean;
  isQrPopUpActive?: boolean | null;
  mobileImagePath?: string | null;
  name?: string | null;
  productDetailLink?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  seoInfo?: ResponseSeoInfo;
  shortDescription?: string | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface ResponseCampaignProductDetail {
  bank?: ResponseBank;
  bankName?: string | null;
  heroText?: string | null;
  /** @format int32 */
  id?: number;
  isActiveRecourse?: boolean;
  isButtonActive?: boolean;
  isQrPopUpActive?: boolean;
  name?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
}

export interface ResponseCampaignProgram {
  code?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
}

export interface ResponseCity {
  /** @format int32 */
  code?: number;
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface ResponseCompanyDetail {
  /** @format int32 */
  cityId?: number;
  /** @format int32 */
  districtId?: number;
  /** @format int32 */
  financialYearStart?: number;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  sectorId?: number;
  sectorName?: string | null;
  title?: string | null;
  /** @format int32 */
  typeId?: number;
  typeName?: string | null;
  vknTckn?: string | null;
}

export interface ResponseCompanyInfo {
  /** @format int32 */
  id?: number;
  title?: string | null;
  /** @format int32 */
  typeId?: number;
  vknTckn?: string | null;
}

export interface ResponseCompanyInfoBaseResponseJsonModel {
  data?: ResponseCompanyInfo;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseContentCampaign {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  buttonText?: string | null;
  buttonUrl?: string | null;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActive?: boolean;
  isDeleted?: boolean;
  name?: string | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  shortDescription?: string | null;
  /** @format date-time */
  startDate?: string | null;
  widgets?: ResponseWidget[] | null;
}

export interface ResponseContentCampaignBasic {
  buttonText?: string | null;
  buttonUrl?: string | null;
  /** @format int32 */
  displayOrder?: number;
  domainType?: DomainType;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActive?: boolean;
  isDeleted?: boolean;
  name?: string | null;
  shortDescription?: string | null;
  /** @format date-time */
  startDate?: string | null;
}

export interface ResponseCreditAnalysisCard {
  /** @format double */
  amountMaximum?: number;
  /** @format double */
  amountMinimum?: number;
  /** @format int32 */
  maturityMaximum?: number;
  /** @format int32 */
  maturityMinimum?: number;
  /** @format double */
  maximumAnnualCostRate?: number;
  /** @format double */
  minimumAnnualCostRate?: number;
  suggested?: ResponseCreditAnalysisCardSuggested;
}

export interface ResponseCreditAnalysisCardSuggested {
  /** @format double */
  amount?: number;
  /** @format double */
  annualCostRate?: number;
  /** @format double */
  interestRate?: number;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalAmountWithoutExpense?: number;
  /** @format double */
  totalExpense?: number;
}

export interface ResponseCreditCardCampaign {
  campaignProgram?: ResponseCampaignProgram;
  friendlyUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActive?: boolean;
  name?: string | null;
  recourseLink?: string | null;
  shortDescription?: string | null;
}

export interface ResponseCreditCardCampaignInfo {
  /** @format int32 */
  campaignId?: number;
  campaignProgram?: ResponseCampaignProgram;
  /** @format int32 */
  campaignProgramId?: number;
  /** @format int32 */
  id?: number;
  /** @format date-time */
  participationEndDate?: string | null;
  /** @format date-time */
  participationStartDate?: string | null;
  recourseLink?: string | null;
  /** @format date-time */
  validityEndDate?: string | null;
  /** @format date-time */
  validityStartDate?: string | null;
}

export interface ResponseCreditCardCampaignListPagedApiResponse {
  /** @format int32 */
  first?: number;
  /** @format int32 */
  index?: number;
  items?: ResponseCreditCardCampaign[] | null;
  /** @format int64 */
  last?: number;
  /** @format int32 */
  next?: number;
  /** @format int64 */
  pageSize?: number;
  /** @format int32 */
  prev?: number;
  /** @format int64 */
  total?: number | null;
  /** @format int32 */
  totalPageSize?: number;
}

export interface ResponseCreditCardCategory {
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
}

export interface ResponseDigitalOnBoardingCampaignInfo {
  /** @format int32 */
  campaignId?: number;
  campaignProgram?: ResponseDobCampaignProgram;
  /** @format int32 */
  campaignProgramId?: number;
  /** @format int32 */
  id?: number;
  /** @format date-time */
  participationEndDate?: string | null;
  /** @format date-time */
  participationStartDate?: string | null;
  /** @format date-time */
  validityEndDate?: string | null;
  /** @format date-time */
  validityStartDate?: string | null;
}

export interface ResponseDigitalOnBoardingRecourse {
  /** @format int32 */
  recourseId?: number;
}

export interface ResponseDigitalOnboardingRecourseForward {
  bankCode?: string | null;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  forwardLink?: string | null;
  forwardingWaitTime?: string | null;
  isContract?: boolean;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  recourseId?: number;
  recourseStatus?: RecourseStatusType;
}

export interface ResponseDobCampaignProgram {
  code?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
}

export interface ResponseFeaturedProductInformations {
  iconPath?: string | null;
  /** @format int32 */
  productId?: number;
  title?: string | null;
  value?: string | null;
}

export interface ResponseGetMe {
  /** @format date-time */
  birthDate?: string;
  email?: string | null;
  emailConfirmed?: boolean;
  /** @format int32 */
  id?: number;
  name?: string | null;
  phoneNumber?: string | null;
  surname?: string | null;
  /** @format uuid */
  userTrackingId?: string;
}

export interface ResponseGetMeBaseResponseJsonModel {
  data?: ResponseGetMe;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseGetMeWithCompanyDetails {
  /** @format date-time */
  birthDate?: string;
  companyDetails?: ResponseCompanyDetail[] | null;
  email?: string | null;
  emailConfirmed?: boolean;
  /** @format int32 */
  id?: number;
  name?: string | null;
  phoneNumber?: string | null;
  surname?: string | null;
  /** @format uuid */
  userTrackingId?: string;
}

export interface ResponseGetMeWithCompanyDetailsBaseResponseJsonModel {
  data?: ResponseGetMeWithCompanyDetails;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseHelloBar {
  allowAllPage?: boolean;
  backgroundType?: BackgroundType;
  backgroundValue?: string | null;
  desktopImagePath?: string | null;
  device?: DeviceTypeFlag;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  layoutSize?: LayoutSizeType;
  link?: string | null;
  linkTarget?: string | null;
  mobileImagePath?: string | null;
  name?: string | null;
  tabletImagePath?: string | null;
}

export interface ResponseIntegrationAgraUserInfo {
  companyInfos?: AgraIntegrationCompanyInfo[] | null;
  userInfo?: AgraIntegrationUserInfo;
}

export interface ResponseIntegrationAgraUserInfoBaseResponseJsonModel {
  data?: ResponseIntegrationAgraUserInfo;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseInterestRateTable {
  category?: CategoryEnumType;
  categoryCode?: string | null;
  interestRates?: ResponseStoreInterestRate[] | null;
  lessProducts?: ResponseStoreInterestRate[] | null;
}

export interface ResponseIsCheckPhoneEmail {
  emailErrorMessage?: string | null;
  isErrorEmail?: boolean;
  isErrorPhone?: boolean;
  phoneErrorMessage?: string | null;
}

export interface ResponseIsCheckPhoneEmailBaseResponseJsonModel {
  data?: ResponseIsCheckPhoneEmail;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseLastNews {
  /** @format int32 */
  commentCount?: number;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseMenuItem {
  activeImagePath?: string | null;
  badgeType?: BadgeType;
  description?: string | null;
  /** @format int32 */
  displayOrder?: number;
  hamburgerName?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  itemType?: MenuItemType;
  menuDisplayDeviceType?: MenuDisplayDeviceType;
  menuType?: MenuType;
  /** @format int32 */
  mobileDisplayOrder?: number;
  name?: string | null;
  /** @format int32 */
  parentId?: number;
  subMenuItemList?: ResponseMenuItem[] | null;
  url?: string | null;
}

export interface ResponseMenuItemList {
  customMixMenuItems?: ResponseMenuItem[] | null;
  financialHealthItems?: ResponseMenuItem[] | null;
  footerItems?: ResponseMenuItem[] | null;
  hamburgerItems?: ResponseMenuItem[] | null;
  headerItems?: ResponseMenuItem[] | null;
  metroItems?: ResponseMenuItem[] | null;
  smeCalculationToolsMenuItems?: ResponseMenuItem[] | null;
}

export interface ResponseModuleContent {
  buttonName?: string | null;
  corporation?: string | null;
  deviceType?: DeviceType;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  link?: string | null;
  /** @format int32 */
  moduleId?: number;
  shortDescription?: string | null;
  tagName?: string | null;
  title?: string | null;
}

export interface ResponseModuleProductLoan {
  badge?: string | null;
  bank?: ResponseBank;
  calculate?: ResponseProductModuleCalculate;
  /** @format int32 */
  carStatus?: number;
  /** @format double */
  defaultAmount?: number;
  /** @format int32 */
  defaultMaturity?: number;
  deviceType?: DeviceType;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  isVariableInterest?: boolean;
  loanRateText?: string | null;
  maturities?: SelectListItem[] | null;
  /** @format int32 */
  productId?: number;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  sponsorProduct?: boolean;
  type?: ProductType;
}

export interface ResponseModuleStoreFront {
  contents?: ResponseModuleContent[] | null;
  creditCards?: ResponseProductSearch[] | null;
  description?: string | null;
  displayNonAgreementBank?: boolean;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  interestRateChangeGraficData?: InterestRateChangeGraficData;
  interestRateChangeGraficDataBankIds?: string | null;
  interestRateChangeGraficDataMaturityType?: MaturityType;
  /** @format int32 */
  interestRateChangeGraficDataMaxMaturity?: number | null;
  /** @format int32 */
  interestRateChangeGraficDataMinMaturity?: number | null;
  /** @format int32 */
  maxProductCount?: number;
  moduleWithContentSortList?: ResponseModuleWithContentSortListItem[] | null;
  name?: string | null;
  /** @format int32 */
  pageId?: number;
  products?: ResponseModuleProductLoan[] | null;
  /** @format int32 */
  type?: number;
}

export interface ResponseModuleWithContentSortListItem {
  /** @format int32 */
  id?: number;
  isProduct?: boolean;
  /** @format int32 */
  order?: number;
}

export interface ResponseNativeCard {
  /** @format int32 */
  alternativeCategoryId?: number;
  /** @format int32 */
  alternativeProductId?: number;
  alternativeProductIsShowSponsor?: boolean;
  alternativeProductSponsorText?: string | null;
  /** @format double */
  amountMaximum?: number | null;
  /** @format double */
  amountMinimum?: number | null;
  categoryId?: ProductTypeEnum;
  deviceStatus?: DeviceTypeFlag;
  /** @format int32 */
  id?: number;
  /** @format double */
  informationTooltipLeftToRight?: number | null;
  informationTooltipText?: string | null;
  /** @format double */
  informationTooltipTopToDown?: number | null;
  isActiveInformationTooltip?: boolean;
  isActiveRecourse?: boolean;
  link?: string | null;
  linkType?: LinkTypeEnum;
  /** @format int32 */
  listViewOrder?: number;
  /** @format int32 */
  maturityMaximum?: number | null;
  /** @format int32 */
  maturityMinimum?: number | null;
  name?: string | null;
  nativeCardAttributes?: ResponseNativeCardAttribute[] | null;
  nativeCardPictures?: ResponseNativeCardPicture;
  /** @format int32 */
  pageId?: number;
  /** @format int32 */
  productId?: number;
  redirectionType?: NativeCardRedirectionType;
  target?: string | null;
  type?: NativeCardTypeEnum;
}

export interface ResponseNativeCardAttribute {
  isDeleted?: boolean;
  key?: string | null;
  type?: NativeCardAttributeTypeEnum;
  value?: string | null;
}

export interface ResponseNativeCardPicture {
  altTitle?: string | null;
  desktopImagePath?: string | null;
  mobileImagePath?: string | null;
  tabletImagePath?: string | null;
}

export interface ResponseNoticePay {
  /** @format double */
  grossNoticePay?: number;
  /** @format double */
  incomeTax?: number;
  /** @format double */
  netNoticePay?: number;
  /** @format int32 */
  periodOfNotice?: number;
  /** @format double */
  stampDuty?: number;
}

export interface ResponseOpenPosition {
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  targetLink?: string | null;
}

export interface ResponseOrientationUrlGenerator {
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  category?: ProductEnum;
  /** @format int32 */
  createdBy?: number | null;
  fictionName?: string | null;
  /** @format int32 */
  fictionTypeId?: number | null;
  /** @format int32 */
  id?: number;
  isActive?: boolean;
  link?: string | null;
  /** @format int32 */
  pageId?: number;
  productCategory?: ProductEnum;
  productId?: string | null;
  productName?: string | null;
  /** @format double */
  revenue?: number;
  title?: string | null;
}

export interface ResponsePage {
  desktopImagePath1?: string | null;
  desktopImagePath2?: string | null;
  mobileImagePath1?: string | null;
  mobileImagePath2?: string | null;
  category?: string | null;
  code?: string | null;
  forwardButtonLink?: string | null;
  heroButtonText?: string | null;
  /** @format int32 */
  id?: number;
  midCategory?: string | null;
  name?: string | null;
  pageType?: string | null;
  sharingType?: string | null;
  subCategory?: string | null;
  type?: string | null;
  whatIsTitle?: string | null;
}

export interface ResponsePageSetting {
  mobileButtonLink?: string | null;
  mobileButtonText?: string | null;
  mobileButtonVisibility?: boolean;
}

export interface ResponsePasswordReset {
  message?: string | null;
}

export interface ResponsePasswordResetBaseResponseJsonModel {
  data?: ResponsePasswordReset;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponsePopularArticleModel {
  authorFullName?: string | null;
  authorImagePath?: string | null;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponsePopularCalculation {
  /** @format double */
  amount?: number;
  categoryEnumType?: CategoryEnumType;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  maturity?: number;
  searchUrl?: string | null;
}

export interface ResponsePosProductCompanyFormMailRecourse {
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  emails?: string[] | null;
  recourses?: ResponseRecourseForMailByCompanyForm[] | null;
}

export interface ResponsePosProductRecourseForward {
  bankCode?: string | null;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  forwardLink?: string | null;
  forwardingWaitTime?: string | null;
  isContract?: boolean;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  recourseId?: number;
  recourseStatus?: RecourseStatusType;
}

export interface ResponsePosProductRecourseMail {
  birthDate?: string | null;
  createdOn?: string | null;
  identityNumber?: string | null;
  phoneNumber?: string | null;
}

export interface ResponseProductModuleCalculate {
  applyBaseUrl?: string | null;
  applyUrl?: string | null;
  /** @format int32 */
  bankFinanceType?: number;
  /** @format int32 */
  bankId?: number;
  bankListUrl?: string | null;
  bankLogoPath?: string | null;
  currencyType?: CurrencyType;
  /** @format int32 */
  detailId?: number;
  detailUrl?: string | null;
  /** @format double */
  inCome?: number;
  /** @format double */
  interestRate?: number;
  interestRateText?: string | null;
  isActiveRecourse?: boolean;
  isRedirectDefaultReferralProduct?: boolean;
  isStoreRedirect?: boolean;
  /** @format double */
  monthlyInstallment?: number;
  /** @format double */
  netProfit?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  recourseLink?: string | null;
  redirectButtonText?: string | null;
  /** @format int32 */
  redirectProductId?: number | null;
  redirectProductName?: string | null;
  redirectText?: string | null;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalExpense?: number;
  typeId?: ProductType;
}

export interface ResponseProductSearch {
  /** @format int32 */
  activityStatus?: number;
  /** @format double */
  annualFee?: number;
  annualFeeText?: string | null;
  applicationUrl?: string | null;
  bank?: ResponseProductSearchBank;
  /** @format int32 */
  campaignCount?: number;
  campaignsUrl?: string | null;
  detailUrl?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  earningType?: number;
  friendlyUrl?: string | null;
  heroCampaigns?: ResponseProductSearchHeroCampaign[] | null;
  /** @format int32 */
  id?: number;
  imageForList?: string | null;
  isActiveRecourse?: boolean;
  isCommercial?: boolean;
  isContract?: boolean;
  isSponsorProduct?: boolean;
  isViewScore?: boolean;
  name?: string | null;
  recourseUrl?: string | null;
  redirectText?: string | null;
  /** @format double */
  score?: number;
  /** @format int32 */
  scoreStarPercent?: number;
  sponsorProductTitle?: string | null;
}

export interface ResponseProductSearchBank {
  bankSeoUrl?: string | null;
  /** @format int32 */
  displayOrder?: number;
  friendlyUrl?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface ResponseProductSearchFacet {
  code?: string | null;
  /** @format int32 */
  displayOrder?: number;
  isViewSelections?: boolean;
  name?: string | null;
  options?: ResponseProductSearchFacetOption[] | null;
  placeHolder?: string | null;
}

export interface ResponseProductSearchFacetOption {
  code?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format int32 */
  displayOrder?: number;
  name?: string | null;
}

export interface ResponseProductSearchHeroCampaign {
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  title?: string | null;
}

export interface ResponseRecourse {
  accountNumber?: string | null;
  adwordsCampaign?: string | null;
  /** @format double */
  amount?: number;
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  banks?: Record<string, string>;
  /** @format date-time */
  birthDate?: string | null;
  branchCode?: string | null;
  checkBackFacePath?: string | null;
  /** @format date-time */
  checkDate?: string;
  checkFrontFacePath?: string | null;
  checkNumber?: string | null;
  /** @format int32 */
  cityId?: number;
  cityName?: string | null;
  /** @format date-time */
  createdOn?: string;
  /** @format int32 */
  drawerBankId?: number;
  drawerBankName?: string | null;
  drawerIdentityNumber?: string | null;
  drawerTitle?: string | null;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  /** @format int32 */
  id?: number;
  identityNumber?: string | null;
  invoiceFrontFacePath?: string | null;
  ipAddress?: string | null;
  lastName?: string | null;
  otpCode?: string | null;
  /** @format int32 */
  parentId?: number;
  phoneNumber?: string | null;
  recourseProcessType?: RecourseProcessType;
  redirectionUrl?: string | null;
  referrerUrl?: string | null;
  resourceType?: ComponentType;
  /** @format int32 */
  userId?: number | null;
}

export interface ResponseRecourseForMailByCompanyForm {
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  /** @format date-time */
  birthDate?: string | null;
  birthDateText?: string | null;
  /** @format date-time */
  createdOn?: string;
  createdOnText?: string | null;
  /** @format double */
  endorsementAmount?: number | null;
  identityNumber?: string | null;
  monthlyIncomeType?: MonthlyIncomeType;
  monthlyIncomeTypeText?: string | null;
  phoneNumber?: string | null;
  webSiteAddress?: string | null;
}

export interface ResponseRefreshToken {
  token?: TokenResponse;
}

export interface ResponseRefreshTokenBaseResponseJsonModel {
  data?: ResponseRefreshToken;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseResetPasswordToken {
  message?: string | null;
}

export interface ResponseResetPasswordTokenBaseResponseJsonModel {
  data?: ResponseResetPasswordToken;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseResource {
  adwordsCampaign?: string | null;
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  banks?: Record<string, string>;
  /** @format date-time */
  birthDate?: string;
  /** @format int32 */
  cityId?: number;
  cityName?: string | null;
  componentType?: ComponentType;
  /** @format date-time */
  createdOn?: string;
  email?: string | null;
  firstName?: string | null;
  googleClickId?: string | null;
  googleId?: string | null;
  identityNumber?: string | null;
  ipAddress?: string | null;
  lastName?: string | null;
  /** @format int32 */
  numberOfEmployees?: number;
  otpCode?: string | null;
  /** @format int32 */
  parentId?: number;
  phoneNumber?: string | null;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  recourseStatus?: RecourseStatusType;
  referrerUrl?: string | null;
  /** @format double */
  totalGrossSalary?: number;
  /** @format int32 */
  userId?: number | null;
  userTrackingId?: string | null;
}

export interface ResponseSalaryPromotionCalculate {
  /** @format int32 */
  personPerPrice?: number;
  /** @format int32 */
  totalPrice?: number;
}

export interface ResponseSalaryPromotionRecourseMail {
  cityName?: string | null;
  companyTitle?: string | null;
  /** @format int32 */
  countyId?: number;
  countyName?: string | null;
  createdOn?: string | null;
  email?: string | null;
  firstName?: string | null;
  identityNumber?: string | null;
  lastName?: string | null;
  /** @format int32 */
  numberOfEmployees?: number;
  phoneNumber?: string | null;
  /** @format double */
  totalGrossSalary?: number;
}

export interface ResponseSeoInfo {
  badge?: string | null;
  canonicalUrl?: string | null;
  entity?: string | null;
  /** @format int32 */
  entityId?: number | null;
  friendlyUrl?: string | null;
  headingTitle?: string | null;
  heroDescription?: string | null;
  image?: string | null;
  metaDescription?: string | null;
  page?: ResponsePage;
  shortDesc?: string | null;
  statusParameters?: StatusParametersTypeEnum;
  title?: string | null;
}

export interface ResponseSeverancePay {
  /** @format double */
  grossSeverancePay?: number;
  /** @format double */
  netSeverancePay?: number;
  /** @format double */
  severancePayBaseFee?: number;
  /** @format double */
  stampDuty?: number;
  /** @format double */
  workingDay?: number;
}

export interface ResponseSlider {
  alternateText?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  link?: string | null;
  name?: string | null;
}

export interface ResponseSmeLoanOfferPreparing {
  bankCode?: string | null;
  bankName?: string | null;
}

export interface ResponseSmeLoanOffers {
  /** @format double */
  amount?: number;
  /** @format double */
  maxCreditLimit?: number;
  /** @format int32 */
  maxRate?: number | null;
  /** @format int32 */
  minRate?: number | null;
  paymentPlan?: SmeLoanOffer[] | null;
  preApprovedApplicationId?: string | null;
  /** @format int32 */
  recourseId?: number;
}

export interface ResponseSmeLoanProductBank {
  /** @format double */
  amount?: number;
  bankCode?: string | null;
  /** @format int32 */
  bankFinanceType?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  /** @format double */
  firstInterestRate?: number | null;
  /** @format int32 */
  id?: number;
  /** @format double */
  interestRate?: number;
  isActive?: boolean;
  isActiveIntegration?: boolean;
  isActiveRecourse?: boolean;
  isContract?: boolean;
  isSpecialProduct?: boolean;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  productName?: string | null;
  /** @format double */
  rate?: number;
  recourseLink?: string | null;
  recourseStatus?: RecourseStatusType;
  /** @format double */
  totalAmount?: number;
}

export interface ResponseSmeLoanRecourse {
  /** @format int32 */
  recourseId?: number;
}

export interface ResponseSmeLoanRecourseForward {
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  forwardLink?: string | null;
  forwardingWaitTime?: string | null;
  isContract?: boolean;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  recourseId?: number;
  recourseStatus?: RecourseStatusType;
}

export interface ResponseSmeLoanRecourseStatus {
  /** @format double */
  amount?: number;
  /** @format int32 */
  bankId?: number;
  /** @format double */
  interestRate?: number;
  isSuccess?: boolean;
  /** @format int32 */
  maturity?: number;
  /** @format int32 */
  productId?: number;
}

export interface ResponseSmeLoanRecourseStatusBaseResponseJsonModel {
  data?: ResponseSmeLoanRecourseStatus;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseSmeLoanStartTransaction {
  bankRedirectionUrl?: string | null;
}

export interface ResponseSmeLoanStartTransactionBaseResponseJsonModel {
  data?: ResponseSmeLoanStartTransaction;
  errors?: ErrorModel[] | null;
  hasError?: boolean;
  isSuccess?: boolean;
}

export interface ResponseStoreAccountCampaign {
  /** @format int32 */
  campaignId?: number;
  friendlyUrl?: string | null;
  imagePath?: string | null;
  name?: string | null;
  shortDescription?: string | null;
}

export interface ResponseStoreCalculateByInterestRate {
  /** @format double */
  amount?: number;
  calculations?: CalculateByInterestRateModel[] | null;
  /** @format double */
  interestRate?: number;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  /** @format double */
  totalBsmv?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalKkdf?: number;
  /** @format double */
  totalMonthlyInstallment?: number;
}

export interface ResponseStoreCampaignSummary {
  /** @format int32 */
  displayOrder?: number;
  friendlyUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  name?: string | null;
  shortDescription?: string | null;
}

export interface ResponseStoreFeaturedCampaign {
  /** @format int32 */
  campaignCount?: number;
  campaignCountAsString?: string | null;
  campaignInfos?: ResponseStoreCampaignSummary[] | null;
  /** @format int32 */
  productId?: number;
  recourseLink?: string | null;
  title?: string | null;
}

export interface ResponseStoreFrontArticleVote {
  result?: boolean;
}

export interface ResponseStoreFrontAuthorArticleModel {
  authorFullName?: string | null;
  authorImagePath?: string | null;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseStoreFrontAuthorArticleModelListPagedApiResponse {
  /** @format int32 */
  first?: number;
  /** @format int32 */
  index?: number;
  items?: ResponseStoreFrontAuthorArticleModel[] | null;
  /** @format int64 */
  last?: number;
  /** @format int32 */
  next?: number;
  /** @format int64 */
  pageSize?: number;
  /** @format int32 */
  prev?: number;
  /** @format int64 */
  total?: number | null;
  /** @format int32 */
  totalPageSize?: number;
}

export interface ResponseStoreFrontAuthorDetailModel {
  avatar?: string | null;
  biography?: string | null;
  detailUrl?: string | null;
  fullName?: string | null;
  /** @format int32 */
  id?: number;
  shortBio?: string | null;
}

export interface ResponseStoreFrontBank {
  bankSeoUrl?: string | null;
  code?: string | null;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
  seoUrl?: string | null;
}

export interface ResponseStoreFrontCampaignProgram {
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
  stories?: ResponseStoreFrontCampaignStory[] | null;
}

export interface ResponseStoreFrontCampaignStory {
  /** @format int32 */
  campaignId?: number;
  campaignName?: string | null;
  /** @format int32 */
  displayOrder?: number;
  filePath?: string | null;
  link?: string | null;
}

export interface ResponseStoreFrontCategoryArticleModel {
  authorFullName?: string | null;
  authorImagePath?: string | null;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseStoreFrontCategoryArticleModelListPagedApiResponse {
  /** @format int32 */
  first?: number;
  /** @format int32 */
  index?: number;
  items?: ResponseStoreFrontCategoryArticleModel[] | null;
  /** @format int64 */
  last?: number;
  /** @format int32 */
  next?: number;
  /** @format int64 */
  pageSize?: number;
  /** @format int32 */
  prev?: number;
  /** @format int64 */
  total?: number | null;
  /** @format int32 */
  totalPageSize?: number;
}

export interface ResponseStoreFrontCategoryDetailModel {
  detailUrl?: string | null;
  iconPath?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  parentCategory?: ResponseStoreFrontCategoryDetailModel;
  shortDescription?: string | null;
}

export interface ResponseStoreFrontCategoryList {
  /** @format int32 */
  articleCount?: number;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  parentCategory?: ResponseStoreFrontCategoryList;
}

export interface ResponseStoreFrontCreditCardDetail {
  /** @format double */
  additionalCardFee?: number | null;
  additionalCardFeeAsString?: string | null;
  advantages?: string | null;
  /** @format double */
  annualFee?: number;
  annualFeeAsString?: string | null;
  annualFeeText?: string | null;
  attributeMappings?: ResponseStoreFrontProductAttribute[] | null;
  attributes?: ResponseAttribute[] | null;
  attributesOptions?: ResponseAttributeOption[] | null;
  bank?: ResponseBank;
  bankDescription?: string | null;
  /** @format int32 */
  bankId?: number;
  bankTitle?: string | null;
  buttonText?: string | null;
  /** @format int32 */
  campaignProgramId?: number;
  /** @format double */
  cashAdvancedInterest?: number | null;
  cashAdvancedInterestAsString?: string | null;
  description?: string | null;
  /** @format int32 */
  displayOrder?: number;
  domesticCashAdvanceFee?: string | null;
  /** @format int32 */
  earningType?: number;
  earningValue?: string | null;
  foreignCashAdvanceFee?: string | null;
  gainType?: string | null;
  heroCampaigns?: ResponseStoreFrontModuleCreditCardHeroCampaign[] | null;
  /** @format int32 */
  id?: number;
  imageForDetail?: string | null;
  imageForList?: string | null;
  /** @format int32 */
  imageLayout?: number;
  isActive?: boolean;
  isActiveRecourse?: boolean;
  isCommercial?: boolean;
  isContract?: boolean;
  isSponsorProduct?: boolean;
  isViewScore?: boolean;
  /** @format double */
  limitExceedingInterest?: number | null;
  limitExceedingInterestAsString?: string | null;
  minimumPaymentAmount?: string | null;
  /** @format double */
  minimumPaymentCommercialAmountRate?: number;
  /** @format double */
  minimumPaymentIndividualAmountRate?: number;
  name?: string | null;
  /** @format double */
  overdueInterest?: number | null;
  overdueInterestAsString?: string | null;
  paymentSystems?: ResponseStoreFrontPaymentSystem[] | null;
  paymentSystemsAsString?: string | null;
  recourseLink?: string | null;
  /** @format double */
  score?: number;
  /** @format int32 */
  scoreStarPercent?: number;
  /** @format double */
  shoppingInterest?: number | null;
  shoppingInterestAsString?: string | null;
  specialZone?: boolean;
  sponsorProductTitle?: string | null;
}

export interface ResponseStoreFrontCreditCardHomeStory {
  programs?: ResponseStoreFrontCampaignProgram[] | null;
}

export interface ResponseStoreFrontDetailPopularArticleModel {
  authorFullName?: string | null;
  authorImagePath?: string | null;
  /** @format double */
  averageScore?: number;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  desktopImagePath?: string | null;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  mobileImagePath?: string | null;
  shortDescription?: string | null;
  tabletImagePath?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseStoreFrontDigitalOnboardingDetail {
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  bankName?: string | null;
  buttonText?: string | null;
  campaigns?: ResponseBankDigitalOnBoardingInfoCampaign[] | null;
  categoryCampaigns?: ResponseCampaign[] | null;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number | null;
  featuredProductInformations?: ResponseFeaturedProductInformations[] | null;
  hasAnnualFee?: boolean;
  heroText?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActiveRecourse?: boolean;
  isButtonActive?: boolean;
  isQrPopUpActive?: boolean | null;
  /** @format int32 */
  itemIndex?: number;
  logoPath?: string | null;
  mobileRecourseLink?: string | null;
  name?: string | null;
  productDetailLink?: string | null;
  productHeroDescription?: string | null;
  productHeroTitle?: string | null;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  seoUrl?: string | null;
  sponsorProduct?: boolean;
}

export interface ResponseStoreFrontGetRecentArticles {
  authorFullName?: string | null;
  /** @format int32 */
  authorId?: number;
  authorImagePath?: string | null;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  detail?: string | null;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseStoreFrontGetVideoStories {
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
}

export interface ResponseStoreFrontGetVideos {
  /** @format int32 */
  displayOrder?: number;
  embedCode?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  stories?: ResponseStoreFrontGetVideoStories[] | null;
  title?: string | null;
}

export interface ResponseStoreFrontHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  articleCategories?: ResponseStoreFrontCategoryDetailModel[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  basicPopularArticles?: ResponsePopularArticleModel[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categories?: ResponseStoreFrontCategoryList[] | null;
  interestingVideos?: ResponseStoreFrontMostInterestingCategories[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularArticles?: ResponseStoreFrontDetailPopularArticleModel[] | null;
  recentArticles?: ResponseStoreFrontGetRecentArticles[] | null;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  stories?: ResponseStoreFrontGetVideos[] | null;
  widgets?: ResponseWidget[] | null;
}

export interface ResponseStoreFrontModuleCreditCardHeroCampaign {
  /** @format int32 */
  campaignId?: number;
  /** @format int32 */
  displayOrder?: number;
  title?: string | null;
}

export interface ResponseStoreFrontMostInterestingCategories {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortDescription?: string | null;
  videos?: ResponseStoreFrontMostInterestingVideos[] | null;
}

export interface ResponseStoreFrontMostInterestingVideos {
  /** @format int32 */
  displayOrder?: number;
  embedCode?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  title?: string | null;
}

export interface ResponseStoreFrontPaymentPlan {
  /** @format double */
  amount?: number;
  /** @format double */
  interestRate?: number;
  interestRateText?: string | null;
  /** @format int32 */
  maturity?: number;
  monthlyPayments?: MonthlyPayment[] | null;
  /** @format double */
  totalBsmv?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalKkdf?: number;
  /** @format double */
  totalMainAmount?: number;
  /** @format double */
  totalMonthlyInstallment?: number;
}

export interface ResponseStoreFrontPaymentSystem {
  name?: string | null;
}

export interface ResponseStoreFrontPosProduct {
  activityStatus?: ProductActivityStatusTypeEnum;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  blockedPeriod?: string | null;
  campaigns?: PosProductCampaignsModel[] | null;
  commissions?: PosProductCommissionsModel[] | null;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  dynamicTags?: string | null;
  highlightText?: string | null;
  interestRateText?: string | null;
  isActiveRecourse?: boolean;
  isVariableInterest?: boolean;
  /** @format double */
  mininumRate?: number;
  name?: string | null;
  /** @format int32 */
  productId?: number;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  recourseLink?: string | null;
  recourseStatus?: RecourseStatusType;
  seoUrl?: string | null;
  /** @format double */
  subscription?: number | null;
  subscriptionPeriod?: PosSubscriptionPeriodEnum;
  type?: PosTypeEnum;
}

export interface ResponseStoreFrontPosProductDetail {
  activityStatus?: ProductActivityStatusTypeEnum;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  blockedPeriod?: string | null;
  campaigns?: PosProductCampaignsModel[] | null;
  commissions?: PosProductCommissionsModel[] | null;
  description?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  dynamicTags?: string | null;
  highlightText?: string | null;
  interestRateText?: string | null;
  isActiveRecourse?: boolean;
  isVariableInterest?: boolean;
  /** @format double */
  mininumRate?: number;
  name?: string | null;
  /** @format int32 */
  productId?: number;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  recourseLink?: string | null;
  recourseStatus?: RecourseStatusType;
  seoUrl?: string | null;
  /** @format double */
  subscription?: number | null;
  subscriptionPeriod?: PosSubscriptionPeriodEnum;
  type?: PosTypeEnum;
}

export interface ResponseStoreFrontPosProductForm {
  bankCode?: string | null;
  /** @format int32 */
  bankFinanceType?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  /** @format double */
  commissionRate?: number;
  highlightText?: string | null;
  isActiveIntegration?: boolean;
  isActiveRecourse?: boolean;
  isContract?: boolean;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  recourseStatus?: RecourseStatusType;
  /** @format double */
  subscription?: number | null;
  subscriptionPeriod?: PosSubscriptionPeriodEnum;
  type?: PosTypeEnum;
}

export interface ResponseStoreFrontProductAttribute {
  /** @format int32 */
  attributeId?: number;
  /** @format int32 */
  attributeOptionId?: number;
  customValue?: string | null;
}

export interface ResponseStoreFrontProductSearchFacet {
  code?: string | null;
  /** @format int32 */
  displayOrder?: number;
  isViewSelections?: boolean;
  name?: string | null;
  options?: ResponseStoreFrontProductSearchFacetOption[] | null;
  placeHolder?: string | null;
}

export interface ResponseStoreFrontProductSearchFacetOption {
  code?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format int32 */
  displayOrder?: number;
  isSelected?: boolean;
  name?: string | null;
}

export interface ResponseStoreFrontRecourseForward {
  /** @format double */
  annualFee?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  forwardLink?: string | null;
  forwardingWaitTime?: string | null;
  isContract?: boolean;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  recourseId?: number;
  recourseStatus?: RecourseStatusType;
}

export interface ResponseStoreFrontRedirectProduct {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format int32 */
  bankId?: number;
  bankLogo?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  redirectButtonText?: string | null;
  redirectText?: string | null;
  redirectUrl?: string | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface ResponseStoreFrontSearchArticleModel {
  authorFullName?: string | null;
  authorImagePath?: string | null;
  /** @format int32 */
  commentCount?: number;
  /** @format date-time */
  createdOn?: string;
  detailUrl?: string | null;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isAmp?: boolean;
  shortDescription?: string | null;
  title?: string | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseStoreFrontSearchPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  articles?: ResponseStoreFrontSearchArticleModel[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  basicPopularArticles?: ResponsePopularArticleModel[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  categories?: ResponseStoreFrontCategoryList[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface ResponseStoreFrontSeveranceAndNoticePay {
  noticePay?: ResponseNoticePay;
  /** @format double */
  payableNetPay?: number;
  severancePay?: ResponseSeverancePay;
}

export interface ResponseStoreFrontSmeLoan {
  /** @format double */
  amount?: number;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  bankRedirectLink?: string | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  financialReportDescription?: string | null;
  /** @format double */
  firstInterestRate?: number | null;
  /** @format int32 */
  id?: number;
  /** @format double */
  interestRate?: number;
  interestRateText?: string | null;
  isActiveIntegration?: boolean;
  isActiveRecourse?: boolean;
  isLowestInterestRate?: boolean;
  isQrPopUpActive?: boolean;
  isSpecialProduct?: boolean;
  isVariableInterest?: boolean;
  loanRateText?: string | null;
  /** @format double */
  maturity?: number;
  monthProduct?: boolean;
  /** @format double */
  monthlyInstallment?: number;
  name?: string | null;
  /** @format int32 */
  productId?: number;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  recourseStatusTypeEnum?: RecourseStatusTypeEnum;
  seoUrl?: string | null;
  specialZone?: boolean;
  sponsorProduct?: boolean;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalInterestWithTaxes?: number;
}

export interface ResponseStoreFrontSmeLoanDetail {
  /** @format double */
  amount?: number;
  /** @format double */
  annualCostRate?: number;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  description?: string | null;
  /** @format int32 */
  displayOrder?: number;
  document?: string | null;
  dynamicTags?: string | null;
  /** @format double */
  expenseAmount?: number;
  /** @format double */
  expensePercent?: number | null;
  /** @format double */
  firstInterestRate?: number | null;
  /** @format int32 */
  id?: number;
  insurance?: string | null;
  /** @format double */
  interestRate?: number;
  interestRateText?: string | null;
  isActive?: boolean;
  isActiveIntegration?: boolean;
  isActiveRecourse?: boolean;
  isQrPopUpActive?: boolean;
  isSpecialProduct?: boolean;
  isVariableInterest?: boolean;
  loanRateText?: string | null;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  name?: string | null;
  paymentPlan?: ResponseStoreFrontPaymentPlan;
  productTypeText?: string | null;
  qrCoverImage?: string | null;
  qrPopUpButtonText?: string | null;
  qrPopUpDescription?: string | null;
  qrPopUpLink?: string | null;
  qrPopUpTitle?: string | null;
  recourseLink?: string | null;
  recourseStatus?: RecourseStatusTypeEnum;
  redirectButtonText?: string | null;
  /** @format int32 */
  redirectProductId?: number | null;
  redirectText?: string | null;
  seoUrl?: string | null;
  specialZone?: boolean;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalInterestWithTaxes?: number;
}

export interface ResponseStoreFrontSmeLoanForm {
  /** @format double */
  amount?: number;
  bankCode?: string | null;
  /** @format int32 */
  bankFinanceType?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  bankSmeLoanSeoUrl?: string | null;
  /** @format double */
  expenseAmount?: number;
  isContract?: boolean;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format double */
  rate?: number;
  recourceStatus?: RecourseStatusType;
  /** @format double */
  totalAmount?: number;
}

export interface ResponseStoreFrontVehicleBankBrand {
  bankCode?: string | null;
  /** @format int32 */
  bankId?: number;
  brands?: ResponseStoreFrontVehicleBrand[] | null;
}

export interface ResponseStoreFrontVehicleBrand {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface ResponseStoreFrontVehicleLoan {
  /** @format double */
  amount?: number;
  bank?: ResponseBank;
  /** @format int32 */
  bankId?: number;
  bankRedirectLink?: string | null;
  /** @format int32 */
  carStatus?: number;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  /** @format double */
  firstInterestRate?: number | null;
  /** @format int32 */
  id?: number;
  /** @format double */
  interestRate?: number;
  isActiveRecourse?: boolean;
  isLowestInterestRate?: boolean;
  isRedirectToForm?: boolean;
  isSpecialProduct?: boolean;
  isVariableInterest?: boolean;
  loanRateText?: string | null;
  /** @format double */
  maturity?: number;
  monthProduct?: boolean;
  /** @format double */
  monthlyInstallment?: number;
  name?: string | null;
  /** @format int32 */
  productId?: number;
  productType?: ProductEnumType;
  productTypeText?: string | null;
  recourseLink?: string | null;
  recourseStatusTypeEnum?: RecourseStatusType;
  seoUrl?: string | null;
  specialZone?: boolean;
  sponsorProduct?: boolean;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalInterestWithTaxes?: number;
  /** @format int32 */
  vehicleModelId?: number | null;
}

export interface ResponseStoreFrontVehicleLoanFormProduct {
  /** @format double */
  amount?: number;
  /** @format double */
  appIncomeUnit?: number;
  bankCreditCardSeoUrl?: string | null;
  /** @format int32 */
  bankFinanceType?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  isContract?: boolean;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format double */
  rate?: number;
  recourceStatus?: RecourseStatusType;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalCost?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  webIncomeUnit?: number;
}

export interface ResponseStoreGrossNetSalary {
  /** @format double */
  avgOfEmployerTotalCost?: number;
  /** @format double */
  avgOfGrossSalary?: number;
  /** @format double */
  avgOfIncomeTax?: number;
  /** @format double */
  avgOfMinimumSubsistenceAllowance?: number;
  /** @format double */
  avgOfNetSalary?: number;
  /** @format double */
  avgOfSsiEmployeePremium?: number;
  /** @format double */
  avgOfSsiEmployerPremium?: number;
  /** @format double */
  avgOfStampDuty?: number;
  /** @format double */
  avgOfTotalCost?: number;
  /** @format double */
  avgOfTotalSalary?: number;
  /** @format double */
  avgOfUnemploymentEmployeePremium?: number;
  calculatedValues?: ResponseStoreGrossNetSalaryPerMonth[] | null;
  /** @format double */
  disabilityDiscount?: number;
  /** @format double */
  sumOfEmployerTotalCost?: number;
  /** @format double */
  sumOfGrossSalary?: number;
  /** @format double */
  sumOfIncomeTax?: number;
  /** @format double */
  sumOfMinimumSubsistenceAllowance?: number;
  /** @format double */
  sumOfNetSalary?: number;
  /** @format double */
  sumOfSsiEmployeePremium?: number;
  /** @format double */
  sumOfSsiEmployerPremium?: number;
  /** @format double */
  sumOfStampDuty?: number;
  /** @format double */
  sumOfTotalCost?: number;
  /** @format double */
  sumOfTotalSalary?: number;
  /** @format double */
  sumOfUnemploymentEmployeePremium?: number;
  /** @format int32 */
  year?: number;
}

export interface ResponseStoreGrossNetSalaryPerMonth {
  /** @format double */
  employerCost?: number;
  /** @format double */
  grossSalary?: number;
  /** @format double */
  incomeTaxBase?: number;
  /** @format double */
  minimumSubsistenceAllowance?: number;
  /** @format int32 */
  month?: number;
  /** @format double */
  monthlyIncomeTax?: number;
  /** @format double */
  netSalary?: number;
  /** @format double */
  ssiEmployeePremium?: number;
  /** @format double */
  ssiEmployerPremium?: number;
  /** @format double */
  stampDuty?: number;
  /** @format double */
  totalCost?: number;
  /** @format double */
  totalIncomeTaxBase?: number;
  /** @format double */
  totalSalary?: number;
  /** @format double */
  unemploymentEmployeePremium?: number;
}

export interface ResponseStoreInterestRate {
  activityStatus?: ProductActivityStatusTypeEnum;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  brandName?: string | null;
  /** @format double */
  defaultAmount?: number;
  /** @format int32 */
  defaultMaturity?: number;
  /** @format int32 */
  financeType?: number;
  isActiveRecourse?: boolean;
  /** @format double */
  maximumAmount?: number;
  /** @format int32 */
  maximumMaturity?: number;
  /** @format double */
  maximumRate?: number;
  /** @format double */
  minimumAmount?: number;
  /** @format int32 */
  minimumMaturity?: number;
  /** @format double */
  minimumRate?: number;
  modelName?: string | null;
  template?: string | null;
  url?: string | null;
  /** @format int32 */
  vehicleModelId?: number | null;
}

export interface ResponseStoreUnemploymentPay {
  /** @format double */
  grossUnemploymentPay?: number;
  /** @format double */
  netUnemploymentPay?: number;
  /** @format int32 */
  periodOfUnemploymentPay?: number | null;
  /** @format double */
  stampDuty?: number;
  /** @format double */
  totalNetUnemploymentPay?: number;
}

export interface ResponseSubscribe {
  message?: string | null;
}

export interface ResponseTeamMember {
  /** @format int32 */
  createdBy?: number;
  /** @format date-time */
  createdOn?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  isActive?: boolean;
  isDeleted?: boolean;
  linkedinUrl?: string | null;
  name?: string | null;
  surname?: string | null;
  title?: string | null;
  /** @format int32 */
  updatedBy?: number | null;
  /** @format date-time */
  updatedOn?: string | null;
}

export interface ResponseTeamMemberStoreFront {
  /** @format int32 */
  departmentId?: number;
  departmentName?: string | null;
  teamMembers?: ResponseTeamMember[] | null;
}

export interface ResponseUserInfo {
  emailToSha256?: string | null;
  phoneNumberToSha256?: string | null;
  email?: string | null;
  name?: string | null;
  phoneNumber?: string | null;
  surname?: string | null;
  /** @format int32 */
  userId?: number;
  /** @format uuid */
  userTrackingId?: string;
}

export interface ResponseVatCalculation {
  /** @format double */
  amountMoney?: number;
  /** @format double */
  calculatedAmountMoney?: number;
  /** @format int32 */
  calculationType?: number;
  /** @format double */
  vatAmount?: number;
}

export interface ResponseVehicleLoanBank {
  bankSeoUrl?: string | null;
  code?: string | null;
  /** @format int32 */
  financeType?: number;
  /** @format int32 */
  id?: number;
  logoPath?: string | null;
  name?: string | null;
  seoUrl?: string | null;
}

export interface ResponseVehicleLoanRecourseForwardProduct {
  /** @format double */
  amount?: number;
  /** @format double */
  appIncomeUnit?: number;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  forwardLink?: string | null;
  forwardingWaitTime?: string | null;
  isContract?: boolean;
  /** @format int32 */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format double */
  rate?: number;
  /** @format int32 */
  recourseId?: number;
  recourseStatus?: RecourseStatusType;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  totalCost?: number;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  webIncomeUnit?: number;
}

export interface ResponseVideoContent {
  description?: string | null;
  embedCode?: string | null;
  imagePath?: string | null;
  showInHero?: boolean;
  title?: string | null;
  videoLabelText?: string | null;
  videoTargetLink?: string | null;
}

export interface ResponseWidget {
  details?: ResponseWidgetDetail[] | null;
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortDescription?: string | null;
  /** @format int32 */
  type?: number;
}

export interface ResponseWidgetDetail {
  backgroundColor?: string | null;
  buttonText?: string | null;
  /** @format date-time */
  createdOn?: string | null;
  description?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format int32 */
  id?: number;
  imagePath?: string | null;
  link?: string | null;
  linkTarget?: string | null;
  linkText?: string | null;
  secondLink?: string | null;
  secondLinkText?: string | null;
  subTitle?: string | null;
  thirdLink?: string | null;
  thirdLinkText?: string | null;
  title?: string | null;
}

export interface SalaryPromotionApplyPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  averageSalaryPerEmployees?: AverageSalaryPerEmployee[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  cities?: ResponseCity[] | null;
  /** @format int32 */
  displayOrder?: number;
  hasSaved?: boolean;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SalaryPromotionApplySuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  applyBanks?: Record<string, string>;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format int32 */
  displayOrder?: number;
  hasSaved?: boolean;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SalaryPromotionHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  averageSalaryPerEmployees?: AverageSalaryPerEmployee[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseStoreAccountCampaign[] | null;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export type SalarypromotionApplyFormCreateData = SalaryPromotionApplySuccessPageModel;

export type SalarypromotionApplyFormCreateError = string[];

export type SalarypromotionApplyFormListData = SalaryPromotionApplyPageModel;

export type SalarypromotionApplyFormListError = string[];

export type SalarypromotionCalculateListData = ResponseSalaryPromotionCalculate;

export type SalarypromotionCalculateListError = string[];

export interface SalarypromotionCalculateListParams {
  /** @format int32 */
  Id?: number;
  /** @format int32 */
  PersonCount?: number;
}

export type SalarypromotionHomeListData = SalaryPromotionHomePageModel;

export type SalarypromotionHomeListError = string[];

export interface SalarypromotionHomeListParams {
  ChannelCampaignCode?: string;
}

export type SalarypromotionListListData = ResponseResource[];

export type SalarypromotionListListError = string[];

export interface SalarypromotionListListParams {
  /** @format int32 */
  ParentId?: number;
  /** @format int32 */
  UserId?: number;
  PhoneNumber?: string;
}

export type SalarypromotionTasksRecoursemailListData = ResponseSalaryPromotionRecourseMail;

export type SalarypromotionTasksRecoursemailListError = string[];

export interface SalarypromotionTasksRecoursemailListParams {
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
}

export interface ScoreHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  lastNews?: ResponseLastNews[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  responsePageSettings?: ResponsePageSetting;
  responseSliders?: ResponseSlider[] | null;
  responseVideos?: ResponseVideoContent[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export type ScoreapplicationHomeListData = ScoreHomePageModel;

export type ScoreapplicationHomeListError = string[];

export interface SelectListGroup {
  disabled?: boolean;
  name?: string | null;
}

export interface SelectListItem {
  disabled?: boolean;
  group?: SelectListGroup;
  selected?: boolean;
  text?: string | null;
  value?: string | null;
}

export interface SeveranceAndNoticePayPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  lastNews?: ResponseLastNews[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export type SmeBankListData = BankingModel;

export type SmeBankListError = string[];

export type SmeDetailListData = SmeDetailModel;

export type SmeDetailListError = string[];

export interface SmeDetailListParams {
  BankSeoUrl?: string;
  ProductSeoUrl?: string;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  ChannelCampaignCode?: string;
}

export interface SmeDetailModel {
  advertisements?: ResponseAdvertisement[] | null;
  alternateProducts?: ResponseStoreFrontSmeLoan[] | null;
  alternateProductsTitle?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseStoreAccountCampaign[] | null;
  maturities?: ResponseAttribute;
  otherOrffersLink?: string | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  productInfo?: ResponseStoreFrontSmeLoanDetail;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export type SmeHomeListData = SmeHomePageModel;

export type SmeHomeListError = string[];

export interface SmeHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  campaigns?: ResponseStoreAccountCampaign[] | null;
  maturities?: ResponseAttribute;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularCalculations?: ResponsePopularCalculation[] | null;
  seoInfo?: ResponseSeoInfo;
  sliders?: ResponseSlider[] | null;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export type SmeListDetailData = SmeLoanBankListViewModel;

export type SmeListDetailError = string[];

export interface SmeListDetailParams {
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  ChannelCampaignCode?: string;
  bankSeoUrl: string;
}

export type SmeListListData = SmeLoanListViewModel;

export type SmeListListError = string[];

export interface SmeListListParams {
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  PopularRedirect?: boolean;
  /** @format int32 */
  Count?: number;
  ChannelCampaignCode?: string;
}

export interface SmeLoanBankFormPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankLogoPath?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  productName?: string | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanBankListViewModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  amount?: number;
  bank?: ResponseBank;
  bankOtherProducts?: ResponseBankOtherProductModel;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBankLogoModel;
  breadCrumb?: BreadCrumbModel[] | null;
  creditAnalysisCard?: ResponseCreditAnalysisCard;
  interestRateTable?: ResponseBankInterestRateTable;
  lastNews?: ResponseLastNews[] | null;
  maturities?: ResponseAttribute;
  /** @format int32 */
  maturity?: number;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularCalculations?: ResponsePopularCalculation[] | null;
  products?: ResponseStoreFrontSmeLoan[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanFormModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForm?: ResponseStoreFrontSmeLoanForm;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  recourseForward?: ResponseSmeLoanRecourseForward;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanListViewModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  amount?: number;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  maturities?: ResponseAttribute;
  /** @format int32 */
  maturity?: number;
  nativeCards?: ResponseNativeCard[] | null;
  otherProducts?: ResponseStoreFrontSmeLoan[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularCalculations?: ResponsePopularCalculation[] | null;
  products?: ResponseStoreFrontSmeLoan[] | null;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanOffer {
  /** @format double */
  installmentAmount?: number;
  /** @format double */
  interestRate?: number;
  /** @format int32 */
  term?: number;
  /** @format double */
  totalPaymentAmount?: number;
}

export interface SmeLoanOfferPreparingPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  offerPreparing?: ResponseSmeLoanOfferPreparing;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanOffersPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankCode?: string | null;
  /** @format int32 */
  bankId?: number;
  bankLogoPath?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  seoInfo?: ResponseSeoInfo;
  smeLoanOffers?: ResponseSmeLoanOffers;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanRecourseBankRedirectPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankLogoPath?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanRecourseBankResultPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface SmeLoanSuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  amount?: number;
  /** @format double */
  annualCostRate?: number;
  annualCostRateText?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  detailLink?: string | null;
  /** @format int32 */
  displayOrder?: number;
  /** @format double */
  expenseAmount?: number;
  expenseAmountText?: string | null;
  /** @format double */
  interestAmount?: number;
  interestAmountText?: string | null;
  /** @format double */
  interestRate?: number;
  interestRateText?: string | null;
  isActive?: boolean;
  isActiveRecourse?: boolean;
  isSponsorBank?: boolean;
  loanRateText?: string | null;
  /** @format double */
  maturity?: number;
  /** @format double */
  monthlyInstallment?: number;
  monthlyInstallmentText?: string | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  productId?: number;
  productName?: string | null;
  /** @format int32 */
  quantity?: number;
  /** @format int32 */
  recourseId?: number;
  recourseLink?: string | null;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  /** @format double */
  totalAmount?: number;
  totalAmountText?: string | null;
  /** @format double */
  totalInterest?: number;
  /** @format double */
  totalInterestWithTaxes?: number;
  widgets?: ResponseWidget[] | null;
}

export type SmePosDetailData = SmeDetailModel;

export type SmePosDetailError = string[];

export type SmeRecourseBankformCreateData = ResponseSmeLoanRecourse;

export type SmeRecourseBankformCreateError = string[];

export type SmeRecourseBankformListData = SmeLoanBankFormPageModel;

export type SmeRecourseBankformListError = string[];

export interface SmeRecourseBankformListParams {
  /** @format int32 */
  ProductId?: number;
  /** @format int32 */
  BankId?: number;
}

export type SmeRecourseBankredirectListData = SmeLoanRecourseBankRedirectPageModel;

export type SmeRecourseBankredirectListError = string[];

export interface SmeRecourseBankredirectListParams {
  /** @format int32 */
  BankId?: number;
}

export type SmeRecourseBankresultListData = SmeLoanRecourseBankResultPageModel;

export type SmeRecourseBankresultListError = string[];

export type SmeRecourseCreateData = SmeLoanSuccessPageModel;

export type SmeRecourseCreateError = string[];

export type SmeRecourseFormDetailData = SmeLoanFormModel;

export type SmeRecourseFormDetailError = string[];

export interface SmeRecourseFormDetailParams {
  /** @format double */
  amount?: number;
  /** @format int32 */
  maturity?: number;
  /** @format int32 */
  productId: number;
}

export type SmeRecourseForwardDetailData = SmeLoanForwardModel;

export type SmeRecourseForwardDetailError = string[];

export interface SmeRecourseForwardDetailParams {
  Component?: ComponentType;
  ChannelCampaignCode?: string;
  /** @format int32 */
  ChannelCampaignId?: number;
  /** @format int32 */
  RecourseId?: number;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
  /** @format int32 */
  productId: number;
}

export type SmeRecourseOfferpreparingListData = SmeLoanOfferPreparingPageModel;

export type SmeRecourseOfferpreparingListError = string[];

export interface SmeRecourseOfferpreparingListParams {
  /** @format int32 */
  BankId?: number;
}

export type SmeRecourseOffersListData = SmeLoanOffersPageModel;

export type SmeRecourseOffersListError = string[];

export interface SmeRecourseOffersListParams {
  /** @format int32 */
  RecourseId?: number;
  /** @format int32 */
  ProductId?: number;
  BankCode?: string;
}

export type SmeRecourseOtpsendCreateData = BooleanBaseResponseJsonModel;

export type SmeRecourseOtpsendCreateError = BaseResponseJsonModel1;

export interface SmeRecourseOtpsendCreateParams {
  /** @format int32 */
  UserId?: number;
  PhoneNumber?: string;
  /** @format int32 */
  BankId?: number;
}

export type SmeRecourseOtpverifyCreateData = BooleanBaseResponseJsonModel;

export type SmeRecourseOtpverifyCreateError = BaseResponseJsonModel1;

export interface SmeRecourseOtpverifyCreateParams {
  PhoneNumber?: string;
  Code?: string;
  /** @format int32 */
  BankId?: number;
}

export type SmeRecourseSmeloanstatusListData = ResponseSmeLoanRecourseStatusBaseResponseJsonModel;

export type SmeRecourseSmeloanstatusListError = BaseResponseJsonModel1;

export interface SmeRecourseSmeloanstatusListParams {
  /** @format int32 */
  RecourseId?: number;
}

export type SmeRecourseStarttransactionCreateData = ResponseSmeLoanStartTransactionBaseResponseJsonModel;

export type SmeRecourseStarttransactionCreateError = BaseResponseJsonModel1;

export type SmeRedirectproductListData = ResponseStoreFrontRedirectProduct;

export type SmeRedirectproductListError = string[];

export interface SmeRedirectproductListParams {
  /** @format int32 */
  Id?: number;
  /** @format double */
  Amount?: number;
  /** @format int32 */
  Maturity?: number;
}

export type SmeSendproductlistmailCreateData = boolean;

export type SmeSendproductlistmailCreateError = string[];

export type SmeSurvivorInterestratetableListData = ResponseInterestRateTable;

export type SmeSurvivorInterestratetableListError = string[];

export enum StatusParametersTypeEnum {
  None = 'none',
  Noindex = 'noindex',
  Nofollow = 'nofollow',
  NoIndexAndNoFollow = 'NoIndexAndNoFollow',
  Follow = 'follow',
  Noindexandfollow = 'noindexandfollow',
}

export interface StringInt32KeyValuePair {
  key?: string | null;
  /** @format int32 */
  value?: number;
}

export type T = object;

export type TaxcalculationtoolsCalculateCtListData = CalculationToolsPageModel;

export type TaxcalculationtoolsCalculateCtListError = string[];

export interface TaxcalculationtoolsCalculateCtListParams {
  /** @format double */
  earning?: number;
  /** @format double */
  rate?: number;
}

export type TaxcalculationtoolsCalculateVatListData = CalculationToolsPageModel;

export type TaxcalculationtoolsCalculateVatListError = string[];

export interface TaxcalculationtoolsCalculateVatListParams {
  vatIncluded?: boolean;
  /** @format double */
  totalAmount?: number;
  /** @format double */
  vatRate?: number;
}

export type TaxcalculationtoolsCorporateTaxCalculationListData = CorporateTaxCalculationPageModel;

export type TaxcalculationtoolsCorporateTaxCalculationListError = string[];

export type TaxcalculationtoolsHomeListData = CalculationToolsPageModel;

export type TaxcalculationtoolsHomeListError = string[];

export type TaxcalculationtoolsVatCalculationListData = CalculationToolsPageModel;

export type TaxcalculationtoolsVatCalculationListError = string[];

export interface TokenResponse {
  access_token?: string | null;
  error?: string | null;
  error_description?: string | null;
  /** @format int32 */
  expires_in?: number;
  refresh_token?: string | null;
  token_type?: string | null;
}

export interface UnemploymentPayCalculationPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  applicationYears?: number[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  lastNews?: ResponseLastNews[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
  workedDays?: ResponseAttribute;
}

export interface VatCalculationPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  lastNews?: ResponseLastNews[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanBankListViewModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  amount?: number;
  bank?: ResponseBank;
  bankOtherProducts?: ResponseBankOtherProductModel;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseBankLogoModel;
  breadCrumb?: BreadCrumbModel[] | null;
  /** @format int32 */
  carStatus?: number;
  interestRateTableForNewCar?: ResponseBankInterestRateTable;
  interestRateTableForUsedCar?: ResponseBankInterestRateTable;
  maturities?: MiniResponseAttribute;
  /** @format int32 */
  maturity?: number;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularCalculations?: ResponsePopularCalculation[] | null;
  products?: ResponseStoreFrontVehicleLoan[] | null;
  seoInfo?: ResponseSeoInfo;
  vehicleBrands?: ResponseStoreFrontVehicleBrand[] | null;
  /** @format int32 */
  vehicleModelId?: number | null;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanFormModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  cities?: ResponseCity[] | null;
  contractedBanks?: ResponseBank[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  product?: ResponseStoreFrontVehicleLoanFormProduct;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanForwardModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  product?: ResponseVehicleLoanRecourseForwardProduct;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanHomePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  banks?: ResponseVehicleLoanBank[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  interestRateTable?: ResponseInterestRateTable;
  maturities?: MiniResponseAttribute;
  modules?: ResponseModuleStoreFront[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  popularCalculations?: ResponsePopularCalculation[] | null;
  seoInfo?: ResponseSeoInfo;
  vehicleBrands?: ResponseStoreFrontVehicleBrand[] | null;
  video?: ResponseVideoContent;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanListViewModel {
  advertisements?: ResponseAdvertisement[] | null;
  /** @format double */
  amount?: number;
  bankPartners?: ResponseBankPartners[] | null;
  bankVehicleBrands?: ResponseStoreFrontVehicleBankBrand[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  creditAnalysisCard?: ResponseCreditAnalysisCard;
  lastNews?: ResponseLastNews[] | null;
  maturities?: ResponseAttribute;
  /** @format int32 */
  maturity?: number;
  modules?: ResponseModuleStoreFront[] | null;
  nativeCards?: ResponseNativeCard[] | null;
  otherProducts?: ResponseStoreFrontVehicleLoan[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  products?: ResponseStoreFrontVehicleLoan[] | null;
  seoInfo?: ResponseSeoInfo;
  vehicleBrands?: ResponseStoreFrontVehicleBrand[] | null;
  widgets?: ResponseWidget[] | null;
}

export interface VehicleLoanSuccessPageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankLogoPath?: string | null;
  bankName?: string | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  contractedBanks?: ResponseBank[] | null;
  hasSaved?: boolean;
  isContracted?: boolean;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  /** @format double */
  price?: number;
  /** @format int32 */
  recourseId?: number;
  /** @format int32 */
  revenue?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}

export interface WhoWeArePageModel {
  advertisements?: ResponseAdvertisement[] | null;
  bankPartners?: ResponseBankPartners[] | null;
  breadCrumb?: BreadCrumbModel[] | null;
  page?: ResponsePage;
  /** @format int32 */
  pageId?: number;
  seoInfo?: ResponseSeoInfo;
  widgets?: ResponseWidget[] | null;
}
